import { Injectable } from '@angular/core';
import { IResponse } from "../interfaces";
import { ApiService } from './api.service';
import { EventModel } from '../model/cryptoEvent.model';
import { EventFilterModel } from '../model/cryptoEventFilter.model';

const eventApiUrl = 'api/cryptoevent/';

@Injectable({
  providedIn: 'root'
})
export class CryptoEventService {

  constructor(private api: ApiService) {

  }
  
  public getEvents(filter: EventFilterModel): Promise<IResponse<EventModel[]>> {
    return this.api.post<EventModel[]>(eventApiUrl + "list", filter).toPromise();
  }
}
