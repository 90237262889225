
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input } from "@angular/core";
import { BaseComponent } from '../../base/base.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-verificate-modal',
  templateUrl: "verificate.modal.html",
  styleUrls: ["./verificate.modal.scss"]
})
export class VerificateModal extends BaseComponent  {
  @Input() modalText: string = "Before purchase products from Ripe, you need to pass identity verification first. Please follow the instructions that will be provided next.";
  constructor(
    public activeModal: NgbActiveModal,
    private translate: TranslateService,
    private modalService: NgbModal,
  ) {
    super(translate, modalService)
  }
  
  verify() {
    let t = this;
    t.activeModal.close(true);
  }
}