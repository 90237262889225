import { MediaPlatform } from "../enums/mediaPlatform";

export var mediaPlatform = [
    { value: MediaPlatform.Any, label: "All" },
    { value: MediaPlatform.Spotify, label: "Spotify" },
    { value: MediaPlatform.Instagram, label: "Instagram" },  
    { value: MediaPlatform.TikTok, label: "TikTok" },
    { value: MediaPlatform.SoundCloud, label: "SoundCloud" },
    { value: MediaPlatform.AppleMusic, label: "Apple Music" },
    { value: MediaPlatform.Amazon, label: "Amazon" },
    { value: MediaPlatform.Deezer, label: "Deezer" },
    { value: MediaPlatform.Shazam, label: "Shazam" },
    { value: MediaPlatform.iTunes, label: "iTunes" },
    { value: MediaPlatform.Tidal, label: "Tidal" },
    { value: MediaPlatform.YouTube, label: "YouTube" }
  ];