export var menuitems : {[key: string]: string}  = {
    dashboard :"assets/images/ripe/menuitems/dashboard.svg",
    wallet :"assets/images/ripe/menuitems/wallet.svg",
    invest :"assets/images/ripe/menuitems/invest.svg",
    tracks :"assets/images/ripe/menuitems/tracks.svg",
    indexes:"assets/images/ripe/menuitems/invest.svg",
    documents :"assets/images/ripe/menuitems/documents.svg",
    users: "assets/images/ripe/menuitems/users.svg",
    settings: "assets/images/ripe/menuitems/settings.svg",
    verification: "assets/images/ripe/menuitems/verif.svg",
    referral: "assets/images/ripe/menuitems/invite_friends.svg",
    notifications: "assets/images/ripe/menuitems/notifications.svg",
    faq: "assets/images/ripe/menuitems/faq.svg",
    royalty: "assets/images/ripe/menuitems/royalty.svg",
    events: "assets/images/ripe/menuitems/events.svg"
}