import { Injectable } from '@angular/core';
import { IResponse } from "../interfaces";
import { ApiService } from  './api.service';

const infoAnalyticApi = "api/agreement/";

@Injectable({
  providedIn: 'root'
})
export class AgreementService {

  constructor(private api: ApiService) { }

  public createNewAgreement(answer: boolean): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(infoAnalyticApi + "createnewagreement/" +  answer).toPromise();
  }

  public checkHasAgreed(): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(infoAnalyticApi + "checkhasagreed").toPromise();
  }
}
