<!-- footer start-->
<div class="footer-copyright desktop-show">
  <div class="row ms-1">

    <div *ngFor="let type of existingTypes; index as i" class="col-auto">
      <div class="row">
        <div class="col-auto d-flex align-items-center px-1">
          <a href="{{docUrl(type)}}" target="_blank" class="terms-of-use-link c-white text-decoration-none f-12 f-w-300 align-top my-2"> {{docNames.get(type)}}</a>
        </div>
        <div *ngIf="i + 1 < existingTypes.length" class="col-auto divider desktop align-self-center"></div>
      </div>
    </div>

    <div class="col pe-0 d-flex align-items-center justify-content-end f-12 f-w-600 l-h-150"><span>© {{currentYear}} Ripe Tech Corp. All Rights Reserved.</span></div>
  </div>
</div>