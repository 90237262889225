<button class="btn c-white chat-position custom-chat-button f-w-400 desktop-show" 
        [style.bottom]="isHidden? '40px': '100px'" 
        (click)="openChat()"
        [hidden]="isHiddenSupport">
  <img class="me-2" src="assets/icons/public/chat-icon-green.svg" alt="">{{ 'Support' | lang }}
</button>
<div class="player-container w-100 animate-slideUp" [hidden]="isHidden"> 
  <div class="px-sm-5 px-0 me-auto">
    <img src="/assets/images/x_icon.svg" class="close-btn close-btn-mobile" (click)="close()"/>
    <img src="/assets/images/ripe/close-white-icon.svg" class="close-btn close-btn-desktop" (click)="close()"/>
    <div class="player-wrapper mx-auto px-sm-0">
      <div id="spotify-player" #spotifyPlayer></div>
    </div>
  </div>
  <div id="spotifyInvisibleBlockForMobile"></div>
</div>
