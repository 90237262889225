import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/component/base/base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService, UserService } from '../../../../shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { permissions, reCAPTCHA } from 'src/app/shared/constants';
import { TranslateService } from '@ngx-translate/core';

const reCAPTCHAKey = reCAPTCHA.siteKey;

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent extends BaseComponent implements OnInit {

  public isTelegramEnable: boolean = false;
  public loginForm: FormGroup;
  public showErrorForUser: string = "";
  public formErrors: FormErrors = {
    'email': '',
    'password': '',
  };
  public errorMessage: any;
  public currentUserLang: string;
  public needConfirm: boolean = false;
  public showPass: boolean = false;
  public passType: string = "password";
  public showReCAPTCHA: boolean;
  public typeTwoFactorAuth: string = "";
  public reCaptchaInterval: any = {};

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private userService: UserService,
    private translateService: TranslateService
  ) {
    super(translateService, modalService)
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      confirmationCode: [{ disabled: true, value: '' }, [Validators.required, Validators.minLength(6)]]
    });
   }

   
  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }
  get confirmationCode() { return this.loginForm.get('confirmationCode'); }

  ngOnInit(): void {
    var t = this;
    t.reCaptchaInterval = setInterval(function() {
      if (!!(<any>window).grecaptcha && !!(<any>window).grecaptcha.render) {
        (<any>window).grecaptcha.render(
          'captchaField', 
          { 'sitekey': reCAPTCHAKey, 
            'callback': 'recaptcha_callback', 
            hl: 'en', 
            'theme' : 'dark' 
          });
          clearInterval(t.reCaptchaInterval);
      }
    }, 500);
  }

  login() {
    let t = this;
    if (t.loginForm.invalid) {
      t.markFormGroupTouchedAndDirty(t.loginForm)
      return;
    }

    t.setLoading(true);
    var loginCred = {
      email: t.email.value,
      password: t.password.value,
    };

    if (!t.confirmationCode.disabled) localStorage.setItem("confirmCode", t.confirmationCode.value);

    t.authService.SignIn(loginCred)
      .then((resp) => {
        t.setLoading(false);
        t.getRolesAndNavigate();
      })
      .catch(e => {
        //код на капчу 
        if (!!e.error && !!e.error.error && e.error.error == "failed_reCaptcha"){
          t.showReCAPTCHA = true;
        }
        if (!!e.error && !!e.error.error && e.error.error.indexOf("failed_authorization_") != -1) {
          if(e.error.error.indexOf("Email") != -1)
          {
            t.typeTwoFactorAuth = "Email";
          }
          if (e.error.error.indexOf("Google") != -1)
          {
            t.typeTwoFactorAuth = "Google";
          }
          t.confirmationCode.enable();
          t.needConfirm = true;
        }
        else if (e.error?.error == "invalid_grant_show_captcha") {
          t.showReCAPTCHA = true;
        }

        (<any>window).grecaptcha?.reset();

        t.setLoading(false);
        t.showResponseError(e);
      });
  }

  public getRolesAndNavigate(){
    var t = this;
    t.setLoading(true);
    t.userService.refresh()
      .then(resp => {
        t.setLoading(false);

        // тут надо будет нормальные руты дописать
        if (t.userService.getRole().includes(permissions.Master))
          t.router.navigate(['admin/dashboard']);
        else
          t.router.navigate(['admin/dashboard']);
      })
      .catch(er => {
        t.setLoading(false);
        t.showResponseError(er);
        t.authService.SignOut();
      })
  }

  public hideOrShowPassword(){
    if(this.passType == "password"){
      this.passType = "text";
      this.showPass = true;
    }
    else{
      this.passType = "password";
      this.showPass = false;
    }
  }

}
