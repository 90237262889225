export enum FrontChartInterval {
    alltime = -1,

    day = 0,

    week = 1,

    month = 2,

    three_month = 3,

    year = 4,
    
    six_month = 5
}