import { AfterContentInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, EventService, StatsService, UserService, WalletService } from './shared/services';
import { CryptoUserService } from './shared/services/cryptoUser.service';
import { BannerModal } from './shared/component/modals/banner-modal';
import { BaseComponent } from './shared/component/base/base.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { SurveyModal } from './shared/component/modals/survey-modal';
import { SurveyType } from './shared/enums';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit, AfterContentInit {
  title = 'ui14';
  environment = environment;
  mockScreenInterval: any = {};
  modalRef: NgbModalRef;
  eventSubscription: Subscription;
  modalBgSrc = "assets/images/coins-background-modal.png";

  constructor(
    private translate: TranslateService,
    private modalService: NgbModal,
    private userService: UserService,
    private cryptoUserService: CryptoUserService,
    private authService: AuthService,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private statsService: StatsService,
    private eventService: EventService,
    private walletService: WalletService,
  ) {
    super(translate, modalService);
    var t = this;

    // // Пока не подтянутся стили и файлы отображается черный экран и прелоадер.
    // // Черный экран используется как подстраховка на случай долгой загрузки гифки прелоадера
    // window.onload = () => {
    //   // t.disableMockScreen();
    //   t.mockScreenInterval = setInterval(function() {
    //     if (t.disableMockScreen()) {
    //       clearInterval(t.mockScreenInterval);
    //     }
    //   }, 100);
    // }

    // // Отключаем черный экран и прелоадер через 3 сек,
    // // если по какой-то причине не сработало событие onload
    // setTimeout(function() {
    //   t.mockScreenInterval = setInterval(function() {
    //     if (t.disableMockScreen()) {
    //       clearInterval(t.mockScreenInterval);
    //     }
    //   }, 100);
    // }, 3000);

    // document.body.className = 'light-only';
    // document.body.setAttribute("main-theme-layout", 'light-only');
    // document.getElementsByTagName('html')[0].setAttribute('dir', 'light-only');
    // t.createStyle("preload", true);
    // t.createStyle('color-2');
    // t.createStyle('table');
    // document.body.className = 'light';
    t.translate.use('en');

    t.eventSubscription = t.eventService.HeaderButtonSurveyEvent.subscribe((event) => {
      t.showSurveyModal();
    });

    t.router.events.subscribe(() => {
      let intervals: Promise<any>[] = [];

      if (t.authService.isLoggedIn) {
        if (!t.userService.get() && !t.eventService.isFuncArrIncludes(t.userService.refresh)) {
          intervals.push(t.userService.init());
          t.eventService.addFuncToArrayOfIntervals(t.userService.refresh, 1000 * 60 * 5);
        }

        if (!t.cryptoUserService.get() && !t.eventService.isFuncArrIncludes(t.cryptoUserService.refresh)) {
          intervals.push(t.cryptoUserService.init());
          t.eventService.addFuncToArrayOfIntervals(t.cryptoUserService.refresh, 1000 * 60 * 5);
        }

        if (!t.statsService.getStats() && !t.eventService.isFuncArrIncludes(t.statsService.refreshStats)) {
          intervals.push(t.statsService.initStats());
          t.eventService.addFuncToArrayOfIntervals(t.statsService.refreshStats, 1000 * 60 * 5);
        }

        if (!t.walletService.getGasLimits() && !t.eventService.isFuncArrIncludes(t.walletService.refreshGasLimits)) {
          intervals.push(t.walletService.refreshGasLimits());
          t.eventService.addFuncToArrayOfIntervals(t.walletService.refreshGasLimits, 1000 * 60 * 5);
        }

        if (!t.walletService.getAllBalances() && !t.eventService.isFuncArrIncludes(t.walletService.refreshBalance)){
          intervals.push(t.walletService.initBalance());
          t.eventService.addFuncToArrayOfIntervals(t.walletService.refreshBalance, 1000 * 60);
        }
      }
      Promise.all(intervals)
        .then((value) => { }, (reason) => {
          t.authService.SignOut();
        });
    });

    t.checkUrlParams();

    if (t.environment.enableGoogleAnalytics)
      t.initGoogleAnalytics(window, document, 'script', 'dataLayer', 'GTM-WTB63KQ8');
  }

  disableMockScreen() {
    var mockScreen = document.getElementById("mockScreen");
    if (!mockScreen) return false;

    mockScreen.style.display = "none";
    this.setLoading(false);
    return true;
  }

  ngOnInit(): void {
  }

  // Включаем прелоадер после инициализации дочерних компонентов (в т.ч. прелоадера)
  ngAfterContentInit(): void {
    // this.setLoading(true);
  }

  // чтобы в дочерних компонентах сбрасывать значения скролла
  onActivate(event) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
 }

  // Create style sheet append in head
  createStyle(style, preload = false) {
    var head = document.head;
    var link = document.createElement("link");
    link.type = "text/css";
    link.rel = preload ? "preload" : "stylesheet";
    link.href = window.location.origin + "/assets/css/" + style + ".css";
    if (preload) {
      link.as = "style";
      link.setAttribute("onload", "this.rel='stylesheet'");
    }
    head.appendChild(link);
  }

  checkUrlParams() {
    let t = this;

    // Получение query параметров и проверка наличия 'banner' и 'ref'
    t.currentRoute.queryParams.subscribe(params=>{

      if(params['banner']){
        var ref = t.modalService.open(BannerModal, {
          centered: true,
          backdropClass: 'light-white-backdrop',
          size: 'lg'
        });
        ref.componentInstance.imgUrl = '../../../../../assets/images/ripe/banner_airdrop_bg.png'
      }

      if(params['ref']){
        t.cryptoUserService.refLinkFollow(params['ref']);
      }

      if(params['autoLogin']){
        t.autoLoginInit(params['autoLogin']);
      }
    });
  }

  initGoogleAnalytics(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event:'gtm.js'
    });

    var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  }

  public showSurveyModal() {
    let t = this;
    t.modalRef = t.modalService.open(SurveyModal,
      {
        backdropClass: 'light-white-backdrop',
        centered: true,
        windowClass: 'super-modal-delete-users very-nice-shadow survey-modal',
      });
    t.modalRef.componentInstance.bgImage = t.modalBgSrc;
    t.modalRef.componentInstance.surveyType = SurveyType.IndexSelection;
  }

  public autoLoginInit(code: string){
    var t = this;
    t.setLoading(true);

    var emptyCred = {
      email: "",
      password: ""
    };
    localStorage.setItem("autoLoginCode", code);
    t.authService.SignIn(emptyCred)
      .then((resp) => {
        t.router.navigateByUrl('/overview');
      })
      .catch((e => {
        if (e != null && e.error != null && e.error.error == "invalid_grant") {
          t.showError(t.translate.instant(e.error.error_description));
        }
        else t.showResponseError(e);
      }))
      .finally(() => {
        t.setLoading(false);
      });
  }
}
