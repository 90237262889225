export const environment = {
  envName: 'test',
  production: true,
  apiUrl: 'https://test.ripe-team.dev:444/',
  externalUrls : {
    "TgCommunityLink": 'https://t.me/ripecommunity',
  },
  tokenUrl: 'token',
  botName: 'RipeCapitalTestBot',
  botId: '6408779953',
  buildAdmin: false,
  enableGoogleAnalytics: false,
  enableFacebookAnalytics: false,
  rangoApiKey: '06dd91cf-6c2e-4a70-bef9-6d2a512f7d62',
  walletConnectProjectId: '6000735d97d87658eced2b16da76a746'
};

export const blockexplorers: {[key: string]: string} = {
  bscscan : "https://testnet.bscscan.com/" ,
  etherscan: "https://sepolia.etherscan.io/" 
};