import { Injectable } from "@angular/core";
import { IResponse } from "../interfaces";
import { RefLinkSettingsModel, RefLinkSettingsPostRequest } from "../model";
import { ApiService } from "./api.service";
import { RefLinkType } from "../enums/refLinkType";

const apiUrl = 'api/refLinkSettings/';

@Injectable({
  providedIn: 'root'
})

export class RefLinkSettingsService{

    constructor(private api: ApiService) { }

    public getRefLinkSettingsById(userId: number, refLinkType: RefLinkType): Promise<IResponse<RefLinkSettingsModel>> {
      return this.api.get<RefLinkSettingsModel>(`${apiUrl}getRefLinkSettingsById/${userId}/${refLinkType}`).toPromise();
    }
    
    public getRefLinkSettings(refLinkType: RefLinkType): Promise<IResponse<RefLinkSettingsModel>> {
      return this.api.get<RefLinkSettingsModel>(`${apiUrl}getRefLinkSettings/${refLinkType}`).toPromise();
    }  

    public createOrEditRefLinkSettings(refLinkSettings: RefLinkSettingsPostRequest): Promise<IResponse<boolean>> {
      return this.api.post<boolean>(apiUrl + "createOrEditRefLinkSettings", refLinkSettings).toPromise();
    }  

    public deactivateRefLink(userId: number, refLinkType: RefLinkType): Promise<IResponse<boolean>> {
      return this.api.get<boolean>(`${apiUrl}deactivateRefLink/${userId}/${refLinkType}`).toPromise();
    } 
}