export var permissions: {[key: string]: string} = {
    Master : "Master",
    User : "User",
    WithdrawMaster : "WithdrawMaster",
    EmailMaster : "EmailMaster",
    TwoFactorMaster : "TwoFactorMaster",
    EventMaster : "EventMaster",
    NotVerified : "NotVerified",
    Manager: "Manager",
    AllUsersMaster: "AllUsersMaster"
}