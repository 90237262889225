import { CurrencyType } from "../../enums";

export class WithdrawPostRequest {
  ConfirmCode : string;
  AddressTo : string;
  Amount : number;
  CurrencyType : CurrencyType;
  currencyTypeWithdraw : number;
  ActualRateId : number;
  GasPrice : number;
  GasLimit : number;
  useReducedGas : boolean;
}