<!-- Page Header Start-->
<div class="page-main-header" id="page-main-header">
  <div class="main-header-right row">
    <div class="ripe-logo col-auto" style="padding: 16px 11px 16px 15px;">
      <img (click)="router.navigateByUrl('overview')" class="pointer" src="assets/images/ripe/logo.svg"/>
    </div>
    
    <div class="nav-right header-nav-right-custom col">
      <ul class="nav-menus fontsize gap-3" style="float: right;">
        
        <li class="cursor-pointer" *ngIf="cryptoUser().isHideMainBanner">
          <button class="desktop-show btn btn-gradient btn-survey" (click)="showSurveyModal()">
            <div>
              {{'buttons.getStarted' | lang}}
            </div> 
            <img src="assets/images/ripe/icons/arrow-right-black.svg" >
          </button>
          <button class="mobile-show btn btn-primary btn-survey" (click)="showSurveyModal()">
            <div> {{'buttons.getStarted' | lang}} </div> 
            <img src="assets/images/ripe/icons/arrow-right-black.svg" >
          </button>
        </li>
        <li class="desktop-show cursor-pointer">
          <a class="btn btn-secondary" href="{{externalUrls.TgCommunityLink}}" target="_blank" style="padding: 2px 4px 2px 12px;" >
            {{'buttons.community' | lang}} <img src="assets/icons/public/telegram.svg" >
          </a>
        </li>

        <li class="onhover-dropdown cursor-pointer desktop-show" id="bell">

          <img src="{{headerNotification.notCheckedCount > 0 ? 'assets/images/ripe/bell.svg' : 'assets/images/ripe/bell-unread.svg'}}">

          <ul class="notification-dropdown onhover-show-div custom-scrollbar" style="max-height: 272px; overflow: hidden scroll;">
            <li *ngIf="headerNotification.notCheckedCount > 0" class="text-center">
              
              <button type="button" (click)="checkAllNotifications()" class="btn btn-outline-info mb-3 w-100">
                {{'buttons.checkAllNotifications' | lang}}
              </button>
            </li>
            <li *ngFor="let notif of headerNotification.notifications">
              <div class="media" (click)="checkNotification(notif)">
                <div class="media-body">
                  <div class="row">
                    <div class="col-auto pe-0 pt-1">
                        <img src="assets/images/ripe/notif_type_{{notif.type}}.svg">
                    </div>
                    <div class="col">
                        <div class="f-20 f-w-600 text-uppercase"
                            [ngClass]="{'c-green': notif.type == notifTypeEnum.Notification, 
                            'c-red': notif.type == notifTypeEnum.Attention}">
                            {{notif.title| lang}}
                        </div>                
                        <p *ngFor="let bodyText of notif.body" class="mb-0" [innerHtml]="bodyText"></p>                
                    </div>
                  </div> 
                </div>
              </div>
              <hr class="w-100">
            </li>
            <li *ngIf="!headerNotification.notifications || headerNotification.notifications.length == 0" class="txt-dark">
              {{'No new notifications'| lang}}
            </li>
          </ul>
        </li>

        <ng-container>
          <li class="desktop-show">
            <div class="media align-items-center">
              <img src="assets/images/ripe/settings.svg" (click)="toSettings()" style="cursor: pointer;">
            </div>
          </li>
          <li class="onhover-dropdown cursor-pointer desktop-show">
            <div class="media align-items-center">
              <img class="avatar-circle-36" src="{{getAvatar()}}">
            </div>
            <ul class="profile-dropdown onhover-show-div p-20">
              <li (click)="toProfile()">
                <img src="assets/images/ripe/profile.svg"><span class="ms-3">{{'Profile'| translate}}</span>
              </li>
              <li (click)="authService.SignOut()">
                <img src="assets/images/ripe/logout.svg"><span class="ms-3">{{'Logout'| translate}}</span></li>
            </ul>
          </li>
          <li class="mobile-show">
            <img src="assets/images/ripe/burger.svg" (click)="collapseSidebar()"/>
          </li>
        </ng-container>

      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->