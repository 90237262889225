import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService, TokenService, ApiService } from './shared/services';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AdminGuard } from './shared/guard';
import { AuthComponent } from './auth/auth.component';
import { ConfirmationCodeModule } from './auth/confirmation-code/confirmation-code.module';
import { LoginModule } from './auth/login/login.module';
import { RegisterModule } from './auth/register/register.module';
import { ResetPassModule } from './auth/reset-password/reset-password.module';
import { AdminLoginModule } from './components/admin/auth/login';
import { NotFoundModule } from './auth/not-found/not-found.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    ConfirmationCodeModule,
    LoginModule,
    RegisterModule,
    ResetPassModule,
    AdminLoginModule,
    NotFoundModule
  ],
  providers: [
    AuthService, 
    TokenService, 
    ApiService, 
    AdminGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
