<!-- Абсолютно черный экран на время подгрузки стилей -->
<!-- <div id="mockScreen" 
  style="
    position: absolute; 
    width: 100%; 
    height: 100%; 
    background-color: #000; 
    z-index: 1000;"
>
</div> -->
<!-- Затемнение для подсветки на его фоне определенных элементов -->
<div id="blackoutScreen" 
  style="
    position: absolute;
    width: 100%; 
    height: 100%; 
    background-color: rgba(0, 0, 0, 0.9); 
    z-index: 1;
    display: none;"
>
</div>
<app-loader></app-loader>
<router-outlet (activate)="onActivate($event)"></router-outlet>
