export class DocumentModel {
    public id: number;
    public title: string;
    public path: string;
    public fileName: string;
    public originalFileName: string;
    public fileId: number;
    public fileUrl: string;
    public fileExtension: string;
    public language: string;
    public isDeleted: boolean;
    public productType?: number;
  }
  