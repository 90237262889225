import { PromocodeType } from "../enums";

export class PromocodeModel {
   id: number;
   name: string;
   code: string;
   activationDateStr : string;
   expirationDateStr: string;
   activationDate: Date;
   expirationDate: Date;
   value: number; 
   minPurchaseAmount: number;
   lockedDaysPeriod: number;
   type: PromocodeType;
   // bonusPercent : number;
}