import { Component } from '@angular/core';
import { BaseComponent } from 'src/app/shared/component/base/base.component';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  isLoggedIn: boolean = false;

  environment = environment;
  constructor(
    public authService: AuthService,
    private router: Router
  ) {
    this.isLoggedIn = authService.isLoggedIn;
  }

  public navigate(){
    var t = this;
    t.router.navigateByUrl(
      t.isLoggedIn? '/overview' : '/auth/login'
    );
  }
}
