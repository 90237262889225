import { Component, NgZone, OnInit, ViewChildren } from '@angular/core';
import { BaseComponent } from 'src/app/shared/component/base/base.component';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService, CryptoUserService, UserService } from '../../shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AgreementService } from 'src/app/shared/services/agreement.service';
import { reCAPTCHA } from 'src/app/shared/constants';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { TwoFactorAuthorizationType } from 'src/app/shared/enums';
import { FacebookAnalyticsService } from 'src/app/shared/services/facebook-analytics.service';

const reCAPTCHAKey = reCAPTCHA.siteKey;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
  @ViewChildren('telegramAuth') telegramAuth: any;

  public loginForm: FormGroup;
  public needConfirm: boolean = false;
  public showPass: boolean = false;
  public passType: string = "password";
  public typeTwoFactorAuth: number = 0;
  public errorText: string = '';
  public botName: string = environment.botName;
  public showReCAPTCHA: boolean = false;
  public reCaptchaInterval: any = {};
  public systemErrorText = 'System error. Please contact technical support';

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private userService: UserService,
    private ngZone: NgZone,
    private agreementService: AgreementService,
    private translate: TranslateService,
    private cryptoUserService: CryptoUserService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private facebookAnalyticsService: FacebookAnalyticsService
  ) {
    super(translate, modalService)

    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }

  ngOnInit(): void {
    // (<any>window).onTelegramAuth = this.onTelegramAuth.bind(this);

    var t = this;
    t.reCaptchaInterval = setInterval(function() {
      if (!!(<any>window).grecaptcha && !!(<any>window).grecaptcha.render) {
        (<any>window).grecaptcha.render(
          'captchaField',
          { 'sitekey': reCAPTCHAKey,
            'callback': 'recaptcha_callback',
            hl: 'en',
            'theme' : 'dark'
          });
          clearInterval(t.reCaptchaInterval);
      }
    }, 500);
  }

  ngAfterViewInit() {
    // this.createTelegramRegScript();
  }

  public createTelegramRegScript() {
    if (document.getElementById("telegram-reg-script") != null) {
      return;
    }

    // var size = "large";
    // var dataRadius = "20";

    const tgScript = document.createElement('script');
    tgScript.src = "https://telegram.org/js/telegram-widget.js";
    // tgScript.src = "https://oauth.tg.dev/js/telegram-widget.js?21";
    // tgScript.setAttribute('id', 'telegram-reg-script');
    // tgScript.setAttribute('data-telegram-login', this.botName || 'samplebot');
    // tgScript.setAttribute('data-size', size);
    // tgScript.setAttribute('data-request-access', 'write');
    // tgScript.setAttribute('data-radius', dataRadius);
    // tgScript.setAttribute('data-onauth', 'onTelegramAuth(user)');
    tgScript.async = true;

    this.telegramAuth.first.nativeElement.appendChild(tgScript);
  }

  loginTelegram() {
    (<any>window).Telegram.Login.auth(
      {
        bot_id: environment.botId,
        request_access: true
      },
      (data) => {
        if (!data) {
          return;
        }
        this.onTelegramAuth(data);
      }
    );
  }

  onTelegramAuth(authData: Object): void {
    var t = this;

    authData["first_name"] = authData["first_name"] != null ?
      encodeURIComponent(authData["first_name"]) : authData["first_name"];

    authData["last_name"] = authData["last_name"] != null ?
      encodeURIComponent(authData["last_name"]) : authData["last_name"];

    var authDataStr = JSON.stringify(authData);
    localStorage.setItem("telegramAuthData", authDataStr);

    var emptyCred = {
      email: "",
      password: "",
      confirmCode: ""
    };

    t.setLoading(true);
    t.authService.SignIn(emptyCred)
      .then((resp) => {
        t.setLoading(false);
        t.ngZone.run(() => t.router.navigateByUrl('/overview'));
        localStorage.removeItem("telegramAuthData");
      })
      .catch((e => {
        if (e != null && e.error != null && e.error.error == "invalid_grant") {
          t.ngZone.run(() => t.showError(t.translate.instant(e.error.error_description)));

        } else if (!!e.error && !!e.error.error && e.error.error.indexOf("failed_authorization_") != -1) {
          if(e.error.error.indexOf("Email") != -1) {
            t.typeTwoFactorAuth = -1;
          }

          if (e.error.error.indexOf("Google") != -1) {
            t.typeTwoFactorAuth = 2;
          }

          t.cryptoUserService.CredentialSetted(emptyCred, t.typeTwoFactorAuth);
          t.ngZone.run(() => t.router.navigateByUrl('/auth/confirmCode'));

        } else t.ngZone.run(() => t.showResponseError(e));

        t.ngZone.run(() => t.setLoading(false));
        return;
      }));
  };

  // Simple Login
  login() {
    let t = this;
    if (t.loginForm.invalid) {
      t.markFormGroupTouchedAndDirty(t.loginForm)
      return;
    }

    t.setLoading(true);
    var loginCred = {
      email: t.email.value,
      password: t.password.value,
    };

    localStorage.setItem("Uuid", t.getCookieValue('mindboxDeviceUUID'));
    t.authService.SignIn(loginCred)
      .then(() => {
        t.googleAnalyticsService.pushEvent('form_sign_in', 'form_action', 'sign_in');
        t.facebookAnalyticsService.trackEvent('Lead');
        t.router.navigateByUrl('/overview');
      })
      .catch(e => {
        if(!!e.error.error) {
          t.errorText = e.error.error_description;

          let twoFactorErrors = [`failed_authorization_Email`, `failed_authorization_Google`];

          if(twoFactorErrors.includes(e.error.error)){
            let twoFactorTypeStr = (e.error.error).replace('failed_authorization_', '');
            switch(twoFactorTypeStr){
              case 'Email':
                t.typeTwoFactorAuth = TwoFactorAuthorizationType.Email;
                break;
              case 'Google':
                t.typeTwoFactorAuth = TwoFactorAuthorizationType.GoogleAuth;
                break;
            }
            t.cryptoUserService.CredentialSetted(loginCred, t.typeTwoFactorAuth);
            t.router.navigateByUrl('/auth/confirmCode');
          }

          let reCaptchaErrors = [
            'failed_reCaptcha',
            'invalid_grant_show_captcha'
          ];
          if(reCaptchaErrors.includes(e.error.error)) {
            t.showReCAPTCHA = true;
          }
        } else {
          t.errorText = t.systemErrorText;
        }

        (<any>window).grecaptcha?.reset();
      })
      .finally(() => {
        t.setLoading(false);
        localStorage.removeItem("Uuid");
      });
  }

  public hideOrShowPassword(){
    if(this.passType == "password"){
      this.passType = "text";
      this.showPass = true;
    }
    else{
      this.passType = "password";
      this.showPass = false;
    }
  }

  public navigateTab(route: string){
    var t = this;
    t.router.navigateByUrl('/auth/' + route);
  }
}
