import { ApiService } from "./api.service"
import { IResponse } from "../interfaces";
import { Injectable } from "@angular/core";
import { FAQModel } from "src/app/shared/model/faq.model";

const faqApi = "api/faq";
@Injectable({
    providedIn: 'root'
})
export class FAQService {

    constructor(private api: ApiService) { }

    public getFrequentlyAskedQuestions(): Promise<IResponse<FAQModel[]>> {
        return this.api.get<FAQModel[]>(faqApi + "/getfaq").toPromise();
    }

    public createFaqFrequentlyAskedQuestion(faq: FAQModel) {
        return this.api.post<boolean>(faqApi + "/createfaq", faq).toPromise();
    }

    public getFaqById(): Promise<IResponse<FAQModel>> {
        return this.api.get<FAQModel>(faqApi + "/getfaqbyid").toPromise();
    }

    public getFaqByType(type : number): Promise<IResponse<FAQModel[]>> {
        return this.api.get<FAQModel[]>(faqApi + "/getByType/"+ type ).toPromise();
    }

}