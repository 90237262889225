<div class="col-12 section-header">
    <div class="row">
        <div class="col">
            <span>{{title}}</span>
        </div>
        <div class="col-auto">
            <div *ngIf="showInput">
                <input id="download" type="file" #file class="d-none" (change)="emitUpload(file.files)">
                <label for="download" class="mb-0 float-end">
                    <a class="btn btn-primary">Choose file</a> 
                </label>
            </div>
            <button 
                *ngIf="!!firstButton && firstButton.buttonTitle && firstButton.needShow"
                class="btn ms-2 {{firstButton.className}} cursor-pointer" (click)="emitButton(firstButton)"
                [ngClass]="{'btn--no-text': firstButton.textDesktopOnly, 'mobile-show': firstButton.textMobileOnly}"
            >
                <img *ngIf="!!firstButton.iconUrl" [src]="firstButton.iconUrl" class="me-0 me-sm-2">
                <label [ngClass]="{'desktop-show': firstButton.textDesktopOnly}">{{firstButton.buttonTitle}}</label>
            </button>
            <button 
                *ngIf="!!secondButton && secondButton.buttonTitle && secondButton.needShow"
                class="btn ms-2 {{secondButton.className}} cursor-pointer" (click)="emitButton(secondButton)"
                [ngClass]="{'btn--no-text': secondButton.textDesktopOnly}"
            >
                <img *ngIf="!!secondButton.iconUrl" [src]="secondButton.iconUrl" class="me-0 me-sm-2">
                <label [ngClass]="{'desktop-show': secondButton.textDesktopOnly}">{{secondButton.buttonTitle}}</label>
            </button>
        </div>
    </div>
</div>