import { DepositTabId } from "../component/modals/deposit-modal/depositModalTabs";

export class DepositTabModel {
    id: DepositTabId;
    imageUrl?: string;
    name?: string;
    active: boolean;
    subtabs: DepositTabModel[];

    constructor({      
        id, 
        imageUrl, 
        name, 
        active, 
        subtabs
    }: {id: DepositTabId, imageUrl?: string, name?: string, active: boolean, subtabs: DepositTabModel[]}
    ) {
        this.id = id;
        this.imageUrl = imageUrl;
        this.name = name;
        this.active = active;
        this.subtabs = subtabs;
    }

    
}