<h1 class="text-uppercase text-center text-sm-start f-36 f-w-600">{{"Page not found" | lang}}</h1>
<div class="card-body">
  <div class="login-header align-center-900 text-center text-sm-start">
    <label class="col-10">{{"We’re sorry, the page you requested could not be found" | lang}}</label>
  </div>
  <div class="form-group mt-3 row mx-4 mx-sm-0">
    <button class="btn btn-primary col-12 col-sm-auto" (click)="navigate()">
      {{ isLoggedIn? "Back to Overview" : "Back to Login" | lang}}
    </button>
  </div>
</div>