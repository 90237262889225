export enum InfoAnalyticTypeEnum {
    All = -1,

    ETH = 0,

    Ripe = 1,    

    Sell = 6,

    Commission = 7
}