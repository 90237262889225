export class SellPostRequest {
    gasPrice: number;
    useReducedGas: boolean;
    gasLimit: number;
    successFee: number;
    sum : number;
    address : string;
    confirmCode: number;
    walletType: number;
    currencyType: number;
    actualRateId: number;
    exitFeeAmount: number;
    uuid: string;
}