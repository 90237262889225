import { Injectable } from "@angular/core";
import { ApiService } from "./api.service"
import { IResponse } from "../interfaces"

import { BaseFilterModel, ChartDataModel, UserActivityStatisticsModel, TotalDashboardModel, IndexModel } from "../model";
import { MyChartPostRequest } from "../model/request/myChartPostRequest";
import { ChartSearchModelRequest } from "../model/request/chartSearchModelRequest";
import { TotalChartDataModel } from "../model/totalChartData.model";
import { ComboChartDataModel } from "../model/comboChartData.model";

const apiChartUrl = 'api/charts';
@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor(private api: ApiService) { }

  public getMyChart(request: MyChartPostRequest): Promise<IResponse<TotalChartDataModel>> {
      return this.api.post<TotalChartDataModel>(apiChartUrl + "/getmychart", request).toPromise();
  }

  public getStatisticsChart(request: ChartSearchModelRequest): Promise<IResponse<UserActivityStatisticsModel[]>> {
      return this.api.post<UserActivityStatisticsModel[]>(apiChartUrl + "/getstatisticschart", request).toPromise();
  }

  public getPurchasesChart(filter: BaseFilterModel): Promise<IResponse<ChartDataModel[]>> {
    return this.api.post<any[]>(apiChartUrl + "/getPurchasesChart", filter).toPromise();
  }

  public getSalesChart(filter: BaseFilterModel): Promise<IResponse<ChartDataModel[]>> {
    return this.api.post<any[]>(apiChartUrl + "/getSalesChart", filter).toPromise();
  }

  public getTotalDasboardChart(): Promise<IResponse<TotalDashboardModel[]>> {
    return this.api.get<TotalDashboardModel[]>(apiChartUrl + "/totaldashboard").toPromise()
  }

  public getComboChart(request: MyChartPostRequest): Promise<IResponse<ComboChartDataModel>> {
    return this.api.post<ComboChartDataModel>(apiChartUrl + "/getcombochart", request).toPromise();
  }
}
