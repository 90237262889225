import { FrontChartInterval } from "../enums";

export var FrontChartIntervalList = [
    { value: FrontChartInterval.alltime, label: "alltime", shortLabel: "ALL" },
    { value: FrontChartInterval.day, label: "day", shortLabel: "D" },
    { value: FrontChartInterval.week, label: "week", shortLabel: "W" },
    { value: FrontChartInterval.month, label: "month", shortLabel: "M" },
    { value: FrontChartInterval.three_month, label: "three_month", shortLabel: "3M" },
    { value: FrontChartInterval.year, label: "year", shortLabel: "Y" }
]
