export var limitsForPurchases = {
    minForRIPE: 1, //в долларах
    minForTotalRIPE: 5, // сколько минимально
};

export var limitsForWithdraw = {
    minForRIPE: 3, //в долларах
};

export var limitsForSell = {
    minForRIPE: 0.5, //в токенах
};

export var limitsForNetworkFee = {
    highFee: 15, //в токенах
};