<div class="card mx-auto max-w-400">
  <div class="row card-header mb-0">
      <div class="col">
        <h4 class="text-uppercase c-white">{{'Wallet deposit'}}</h4>
      </div>
    
      <!-- крестик у модалки, может понадобится -->
      <!-- <div class="col">
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
  </div>
  <div class="row card-body d-flex justify-content-center">
    <!-- <div class="f-w-400 f-16 mb-2">
      {{ "You can top up your wallet through crypto. Choose the method that suits you." | lang}}
    </div> -->

    <!-- СКРЫТО ПОКА НЕТ ФИАТА -->
    <!-- <div class="d-flex gap-5 mt-2 justify-content-center mb-3">
      <div 
        *ngFor="let tab of tabs" 
        class="deposit-type-switch col-5 px-0 px-sm-3 py-2 text-center"
        (click)="setMainTabActive(tab)" 
        [ngClass]="{'border-green': tab.active}"
      >
        <img [src]="tab.imageUrl" class="pe-2">
        <span class="f-w-400 f-15">{{ tab.name | lang}}</span>
      </div>
    </div> -->

    <!-- <<<Вложенные табы (если есть) -->
    <div *ngIf="currentMainTab.subtabs.length > 0" class="d-flex gap-5 mt-2 justify-content-center mb-3">
      <div 
        *ngFor="let subTab of currentMainTab.subtabs"
        class="deposit-type-switch col-5 px-0 px-sm-3 py-2 text-center"
        (click)="setSubTabActive(subTab)" 
        [ngClass]="{'border-green': subTab.active}"
      >
        <img [src]="subTab.imageUrl" class="pe-2">
        <span class="f-w-400 f-15">{{ subTab.name | lang}}</span>
      </div>
    </div>
    <!-- >>>Вложенные табы -->

    <div *ngIf="!isNotified && !!currentSubTab && currentSubTab.id == DepositTabId.CRYTO_DEPOSIT_TAB_ID">
      <div class="deposit-notif-section mb-4 d-flex flex-column justify-content-end">
        <div class="px-3 pb-3">
          <div class="mb-2 f-18 text-center f-w-700 c-green text-uppercase deposit-notif-header">
            {{'USDT/USDC Arbitrum One ONLY' | lang}}
          </div>
          <div class="f-16 text-center">
            {{'The service only accepts USDT/USDC Arbitrum. Before sending, make sure that the correct network and token are selected.'
            | lang}}
          </div>
          <hr>
          <div class="theme-form">
            <div class="form-check d-flex justify-content-center">
              <input id="checkbox" type="checkbox" [(ngModel)]="isChecked" class="form-check-input c-black" />
              <label
                for="checkbox"
                class="ps-2 f-14 align-top pt-1"
              >{{ "I've read it and agree" | lang }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <<<КРИПТО-ДЕПОЗИТ -->
    <div *ngIf="isNotified && !!currentSubTab && currentSubTab.id == DepositTabId.CRYTO_DEPOSIT_TAB_ID">
      <div class="deposit-qr-section mb-3">
        <div #qrCode class="qr-code-container mb-2 pt-2" [ngStyle]="{ 'opacity': showQRCode ? '1' : '0'}"></div>
        <div class="row pb-2">
          <div class="col-2 t-right px-2">
            <img src="assets/images/ripe/icons/danger_green.svg">
          </div>              
          <div class="col ps-2">
            <div class="f-16 c-green sds">
              {{'USDT/USDC Arbitrum One ONLY' | lang}}
            </div>                
          </div>
        </div>
      </div>
      <div class="col theme-form">
        <div class="row">
          <label for="walletAddressInput" class="mb-2">{{'Wallet address'|lang}}</label>
          <div class="col form-group">
            <input disabled class="c-gray-light form-control" placeholder="{{'Wallet address'|lang}}" value="{{walletAddress}}" id="walletAddressInput">
          </div>
          <div class="col-auto">
            <button class="btn btn-primary" (click)="copyText(walletAddress)">{{'buttons.copy'|lang}}</button>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-auto pe-2">
            <img src="assets/images/ripe/icons/danger_green.svg">
          </div>              
          <div class="col ps-2">
            <div class="f-14">
              {{'Sending to another network will result in the permanent loss of your funds' | lang}}
            </div>                
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-auto pe-2">
            <img src="assets/images/ripe/icons/danger_green.svg">
          </div>              
          <div class="col ps-2">
            <div class="f-14">
              {{'USDC tokens will be converted automatically to USDT' | lang}}
            </div>                
          </div>
        </div>
      <hr>
      </div>
    </div>
    <!-- >>>КРИПТО-ДЕПОЗИТ -->
    
    <div class="row"> 
      <div class="p-0" [ngClass]="{'col-6': !isNotified, 'col-12': isNotified}">
        <button class="btn btn-outline-primary w-100" type="button"
          (click)="activeModal.dismiss()">{{'buttons.close' | lang}}</button>
      </div>
      <div *ngIf="!isNotified" class="col-6 p-0 ps-2">
        <button class="btn btn-primary w-100" type="button" [disabled]="!isChecked" (click)="continue()">
          {{ 'buttons.continue' | lang}}                
        </button>
      </div>
    </div>
  </div>
</div>