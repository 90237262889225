import { IndexType } from "../enums";

export class IndexModel {
    rate: number;
    type: IndexType;
    totalTokens: number;
    yearCourseChange: number;
    yearCourseChangePercent: number;
    allTimeCourseChange: number;
    allTimeCourseChangePercent: number;
    capitalization: number;
    capitalizationChangePercent: number;
    dayCourseMinChange: number;
    dayCourseMaxChange: number;
    capitalizationChangePercentStr: string;
}