import Big from 'big.js';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { StatsService, WalletService, SettingService } from 'src/app/shared/services';
import { BuyPostRequest, ModalInfoModel, PromocodeModel, StatsModel, TokenCommissionModel } from 'src/app/shared/model';
import { BaseComponent } from '../../base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WithdrawCommission, limitsForPurchases } from 'src/app/shared/constants';
import { CurrencyType, PromocodeType, PromocodeTypeLabelMap } from 'src/app/shared/enums';
import { DepositModal } from '../deposit-modal';
import { promise } from 'protractor';
import { ValidationUtils } from 'src/app/shared/utils/validationUtils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-purchase-modal',
  templateUrl: "purchase.modal.html",
  styleUrls: ["./purchase.modal.scss"]
})
export class PurchaseModal extends BaseComponent implements OnInit, OnDestroy {
  modalRef: NgbModalRef;
  
  @Input() totalAmount: string = "";
  @Input() indexFeeModel: TokenCommissionModel;  
  @Input() transFeeModel: TokenCommissionModel;
  @Input() tokenName: string = "";
  @Input() walletAddress: string = "";

  public minSumForOnePurchase = 1; //наверно нужно получать с бэка
  public maxSumForOnePurchase = 10000000; //наверно нужно получать с бэка
  public tokenAmount: string = "";
  public usdAmount: string = "";
  public userPromocode: PromocodeModel = null;
  public isCodeUsed: boolean = false;  
  public promoCodeLabel: string;
  public buyRequest: BuyPostRequest = new BuyPostRequest();
  public gasLimit = new Big(0)
  public buyForm: FormGroup;
  public PromocodeType = PromocodeType;
  public promocodeTokensUsdt: number;

  public balance: () => number;  
  public getStats: () => StatsModel;
  public ripeToUsdt: () => Big;
  public gasPrice: () => Big;
  public reducedGasPrice: () => Big;
  public getTransFee: (useReduced: boolean) => Big;  
  public getTransFeeUsdt: (useReduced: boolean) => Big;

  constructor(
    public activeModal: NgbActiveModal,
    private translate: TranslateService,
    private modalService: NgbModal,
    private statsService: StatsService,
    private settingService: SettingService,
    private walletService: WalletService,
    private fb: FormBuilder,
    private router: Router,
  ) {
    super(translate, modalService)
    let t = this;
    t.getStats = () => {
      if (!!t.statsService.getStats())
        return t.statsService.getStats();
      else return new StatsModel();
    };
    t.ripeToUsdt = () => {
      return new Big(t.getStats().ripeToUsdt)
    }
    t.buyForm = t.fb.group({
      usdInput: ['0' || '', [Validators.required, Validators.pattern(t.floatNumberPattern), t.tokenAmountValidator.bind(this)]],
      inputCode: ['', [Validators.pattern(t.latinAndNumberPattern), Validators.minLength(3), Validators.maxLength(10)]],
    })

    t.gasPrice = () => { 
      return new Big(t.getStats().gasPrice); 
    };

    t.reducedGasPrice = () => { 
      //Если комиссия NeworkFee > 7,
      // то мы уменьшаем gasPrice пока комиссия не станет приемлемой
      // т.е. не становится меньше 7 
      let price = new Big(t.getStats().maxTransFeeUsdt)
        .mul(t.getStats().usdtToUsd)
        .div(t.getStats().eth2usd)
        .mul(t.gwei)
        .div(t.gasLimit);
      return price < 1 ? 1 : t.MathFloorBig(price, 0);
    };

    t.getTransFee = (useReduced) => {
      let gasPrice = useReduced ? t.reducedGasPrice() : t.gasPrice();
      return t.gasLimit.mul(gasPrice).div(t.gwei);
    };

    t.getTransFeeUsdt = (useReduced) => {
      return t.MathCeilingBig(t.getTransFee(useReduced).mul(t.getStats().eth2usd).div(t.getStats().usdtToUsd), 2);
    };  

    t.balance = () => {
      return t.walletService.getFullBalance("USDT");
    }
  }

  get inputCode() {
    return this.buyForm.get('inputCode');
  }

  async ngOnInit(){
    let t = this;
    await t.statsService.refreshStats();
    await t.setPurchaseGasLimit();
    //document.addEventListener('touchmove', e=>e.preventDefault(), {passive: false}); - если нужно чтобы модалка не скроллилась
  }

  async setPurchaseGasLimit(){
    let t = this;
    t.gasLimit = new Big(await t.walletService.getOperationGasLimit(WithdrawCommission.BuyRipeToken));
  }

  ngOnDestroy() : void {
   // document.removeAllListeners('touchmove'); - если нужно чтобы модалка не скроллилась
  }

  getPercent(value : number)
  {
    return new Big(value).mul(100);
  }

  calcEntryFee() {
    let t = this;
    let usdAmount =  Number(t.usdAmount.replace(',', '.'));
    
    if (!isNaN(usdAmount) && usdAmount >= 0) {
      let sum = new Big(usdAmount).minus(t.getTransFeeUsdt(false)); // сумма без нетворк фи
      let entryFee = sum.div(new Big(100 + t.getStats().entryFee * 100)).mul(t.getStats().entryFee * 100);

      if (entryFee >= 0)
        return t.MathCeilingBig(entryFee, 2);
    }
    return 0;
  }

  tokenToUSDT(){
    let t = this;

    let tokenAmount = t.tokenAmount.includes(',') ? Number(t.tokenAmount.replace(',', '.')) : Number(t.tokenAmount)
    if(!!tokenAmount && tokenAmount != 0 && !isNaN(tokenAmount)) {
      return t.MathFloorBig(new Big(tokenAmount).mul(t.ripeToUsdt()), 2);
    }
    return 0;
  }

  getTotal(useReduced: boolean = false) {          
    let t = this;
    let usdAmount = Number(t.usdAmount.replace(',', '.'));
    return t.MathCeilingBig(new Big(usdAmount), 2);
    //т.к. на бек отправляется значение t.usdAmount, вычисление тотал на фронте не имеет смысла
      //(new Big(t.tokenToUSDT())).add(t.calcEntryFee()).add(t.getTransFeeUsdt(useReduced));        
  }

  tokenAmountValidator(textForValidation: FormGroup) {
    let t = this;
    let error = {};
        
    let inputAmount = Number(textForValidation.value.replace(',', '.'));        
    if (inputAmount < limitsForPurchases.minForRIPE) {
      let errorText = t.translate.instant('errors.minimumUSD');
      error[errorText.replace("#count#", limitsForPurchases.minForRIPE.toString())] = true;      
      return error;
    }

    let transFee = t.isCodeUsed 
        ? new Big(0) 
        : t.getTransFeeUsdt(false);
    if(transFee.gt(inputAmount))
    {
      let errorText = t.translate.instant('errors.insufficientBalanceIncludingFees');
      error[errorText] = true;
      return error;
    }
  }

  buy() {
    let t = this;
    let totalInUsd = +t.tokenAmount * t.ripeToUsdt();
    if (totalInUsd < limitsForPurchases.minForTotalRIPE) {
      t.showError("Minimum purchase amount - 0.5 Index ($5+fees)");
      return;
    }
    if (t.buyForm.valid) {
      let usdAmount = Number(t.usdAmount.replace(',', '.'));
      t.buyRequest.amount = usdAmount;
      t.buyRequest.gasLimit = +t.gasLimit;
      t.buyRequest.currencyType = CurrencyType.RipeToken;
      t.buyRequest.currency = CurrencyType.USDT;
      t.buyRequest.actualRateId = t.getStats().actualRateInfoId;
      t.buyRequest.isSetToken = true;
      t.buyRequest.compensationCoef = t.getStats().compensationCoef;
      t.buyRequest.entryFeeAmount = t.calcEntryFee();
      t.buyRequest.uuid = t.getCookieValue('mindboxDeviceUUID');
      t.buyRequest.buyTokenAmount = Number(t.tokenAmount);
      let hasPromocode = !!t.userPromocode;
      t.buyRequest.promocodeId = hasPromocode ? t.userPromocode.id : null;
      if (t.getTransFeeUsdt(false).lt(t.getStats().maxTransFeeUsdt)){
        t.buyRequest.gasPrice = t.gasPrice();
      }
      else {
        var modalInfo = new ModalInfoModel();
        //информативный блок
        modalInfo.title = t.translate.instant("Attention");
        let descr = t.translate.instant("The commission on the network is now high. ")
        descr += hasPromocode ? "" : t.translate.instant("Do you want to wait and postpone the transaction? ");
        descr += t.translate.instant("As soon as network fee will be less than $#transfee#, your transaction will be proceeded.").replace("#transfee#", t.getTransFeeUsdt(true));
        modalInfo.description = descr;
        modalInfo.showDeclineButton = !hasPromocode;
        modalInfo.buttonDecline = "buttons.noBuyNow";
        let confirmText = hasPromocode ? "buttons.ok" : "buttons.yesWait";
        modalInfo.buttonConfirm = confirmText;
        return t.showModal(modalInfo)
          .then(result => {
            t.buyRequest.useReducedGas = result;
            t.buyRequest.gasPrice = result ? t.reducedGasPrice() : t.gasPrice();
            t.checkBalance();
            t.activeModal.close(t.buyRequest); 
          });
      }
      
      t.checkBalance();
      t.activeModal.close(t.buyRequest); 
    }
  }

  public checkBalance(){
    let t = this;
    if(t.buyRequest.amount > t.balance()){
      t.activeModal.dismiss();
      return t.showConfirm("Warning", "Insufficient funds. You need to deposit USD to your wallet first", true, 'Deposit')
      .then(result => {
        if(result){
          t.navigateTab('/deposit');
        }
      });
    }
  }

  public showDepositModal() {
    let t = this;
    t.modalRef = t.modalService.open(DepositModal,
      {
        backdropClass: 'light-white-backdrop',
        centered: true,        
        windowClass: 'super-modal-delete-users very-nice-shadow',
      });

    t.modalRef.componentInstance.walletAddress = t.walletAddress;
  }

  public inputEnterHandler(ev: any, ngInput: string) {
    ValidationUtils.digitsWithSeparatorInputHandler(ev, ngInput, this.usdRoundDecimals, this.maxSumForOnePurchase);
  }

  public applyPromocode() {
    let t = this;

    if (!t.inputCode.value.trim() || !t.inputCode.valid) return;

    t.setLoading(true);
    // проверка на существование такого промокода и его действительность
    t.settingService.getPromocodeByCode(t.inputCode.value)
      .then(response => {
        if (!!response.data) {
          if(response.data.minPurchaseAmount && response.data.minPurchaseAmount > +t.usdAmount)
            t.showError(`This promocode requires $${response.data.minPurchaseAmount} minimal purchase amount`)
          else
          {
            t.userPromocode = response.data;
            t.promoCodeLabel = PromocodeTypeLabelMap.get(t.userPromocode.type);
            t.isCodeUsed = true;
            t.inputCode.disable();
            t.promocodeTokensUsdt = t.MathCeilingBig(new Big(t.userPromocode.value), 2);
            t.buyForm.get('usdInput').updateValueAndValidity();
            t.showSuccess("Promo code applied");
          }
        }
      })
      .catch(ex => {
        t.showResponseError(ex);
      })
      .finally(() => t.setLoading(false));
  }

  // установить доступный баланс по кнопке All
  public setBalanceToAmount() {
    let t = this;
    
    // доступный баланс пользователя
    let available = t.balance();

    if (!!available && available > 0) {
      t.usdAmount = available.toString();
      t.buyForm.get('usdInput').setValue(t.usdAmount);
    } 
    else {
      t.buyForm.get('usdInput').setValue("0");
    }
    t.markFormGroupTouchedAndDirty(t.buyForm);
    t.buyForm.get('usdInput').updateValueAndValidity();
  }

  // получить количество токенов по вводимой сумме долларов
  public getTokenAmount() {    
    let t = this;
    t.tokenAmount = "0";
    let usdAmount = Number(t.usdAmount.replace(',', '.'));
    if(!isNaN(usdAmount) && usdAmount > 0)
    {
      let transFee = t.isCodeUsed ? new Big(0) : t.getTransFeeUsdt(false);
      let entryFee = t.isCodeUsed ? new Big(0) : t.calcEntryFee();
    
      let freeTokensPromoUsed = t.isCodeUsed && t.userPromocode.type == t.PromocodeType.FreeTokens;
      let usdFromPromocode = freeTokensPromoUsed ? t.promocodeTokensUsdt : 0;

      let totalToken = new Big(usdAmount).minus(entryFee).minus(transFee).add(usdFromPromocode).div(t.getStats().ripeToUsdt);
      if(!transFee.gt(usdAmount) && totalToken.gt(0)) {
        t.tokenAmount = t.MathFloorBig(totalToken, 2).toString();
      }
    }
    return t.tokenAmount;
  }

  navigateTab(route: string){
    var t = this;
    t.activeModal.close();
    t.router.navigateByUrl(route);
  }
}