<div *ngIf="!environment.buildAdmin" class="auth-bg">
    <div class="align-center-900 text-center text-sm-start">
        <img class="h-32" src="assets/images/logo/Logo_full.svg"/>
    </div>
    <img class="auth-disk" src="assets/images/background/login_disk.png">
    <div class="auth-col-width">
        <div class="authentication-box">
            <app-login *ngIf="subpagesMenu[0].isActive"></app-login>
            <app-register *ngIf="subpagesMenu[1].isActive"></app-register>
            <confirmation-code *ngIf="subpagesMenu[2].isActive"></confirmation-code>
            <app-resetpass *ngIf="subpagesMenu[3].isActive"></app-resetpass>
            <app-not-found *ngIf="subpagesMenu[4].isActive"></app-not-found>
        </div>
    </div>
    <div class="mobile-show transparent-card c-white text-center auth-block authentication-box mt-0 mb-5">
        <!--FOOTER REG-->
        <div *ngIf="subpagesMenu[1].isActive">
            <div class="auth-card-footer">
                {{"Already have an account" | lang}}?
                <a class="c-green" href="javascript:void(0)"
                  (click)="navigateTab('login')">{{"Sign In" | lang}}</a>
            </div>
            <hr class="footer-hr mx-auto">
            <div class="auth-card-footer">
                <span class="f-12 c-white">
                    {{"By signing up, you agree to our" | lang}}
                    <a href="{{docUrl(docType.TermsOfUse)}}" target="_blank" class="terms-of-use-link text-decoration-underline align-top c-white">
                        {{"Terms of Use" | lang}}
                    </a>
                    <label class="f-12 align-top">{{" and " |lang}}</label>
                    <a href="{{docUrl(docType.PrivacyPolicy)}}" target="_blank" class="terms-of-use-link text-decoration-underline align-top c-white">
                        {{"Privacy Policy" | lang}} 
                    </a>
                </span>
            </div>
        </div>
        <!--FOOTER LOGIN-->
        <div *ngIf="subpagesMenu[0].isActive">
            <div class="auth-card-footer">
                {{"Forgot your password" | lang}}?
                <a class="c-green" href="javascript:void(0)"
                  (click)="navigateTab('resetPass')">{{"Reset password" | lang}}</a>
            </div>
            <div class="auth-card-footer">
                {{"Don't have an account yet" | lang}}?
                <a class="c-green" href="javascript:void(0)"
                  (click)="navigateTab('reg')">{{"Sign Up" | lang}}</a>
            </div>
            <hr class="footer-hr mx-auto">
        </div>
        <!--FOOTER RESET PASSWORD OR CONFIRM CODE-->
        <div *ngIf="subpagesMenu[3].isActive || subpagesMenu[2].isActive">
            <div class="auth-card-footer">
                <a  
                    class="c-green" 
                    href="javascript:void(0)"
                    (click)="navigateTab('login')"
                >
                    {{"Back to Sign In" | lang}}
                </a>
            </div>
            <hr class="footer-hr mx-auto">
        </div>
        
        <div *ngIf="!subpagesMenu[1].isActive" >
            <span class="f-w-300 f-12">
                © {{ currentYear }} Ripe Tech Corp. All Rights Reserved.
            </span>
        </div>
    </div>

    <div class="desktop-show c-white">
        © {{ currentYear }} Ripe Tech Corp. All Rights Reserved.
    </div>
    <!-- кнопка для чата -->
    <button class="btn c-white chat-position custom-chat-button f-w-400" 
        [style.bottom]="isMobile ? '20px' : '40px'"
        (click)="openChat()"
        [hidden]="isHiddenSupport">
        <img class="me-2" src="assets/icons/public/chat-icon-green.svg" alt="">{{ 'Support' | lang }}
    </button>
</div>

<div *ngIf="environment.buildAdmin" class="auth-bg auth-bg-admin align-items-center" style="background-size: 100% !important;">
    <img class="h-32" src="assets/images/logo/Logo_full.svg"/>
    <div class="authentication-box">
        <app-admin-login *ngIf="subpagesMenu[0].isActive"></app-admin-login>
    </div>
    <div class="align-center c-gray-semi-dark">
        © {{currentYear}} Ripe Tech Corp. All Rights Reserved.
    </div>
</div>