import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  environment = environment;

  pushEvent(
    eventName: string,
    eventCategory: string,
    eventAction: string
  ) {
    if (this.environment.enableGoogleAnalytics) {
      (<any>window).dataLayer?.push({
        event: eventName,
        eventCategory: eventCategory,
        eventAction: eventAction
      });
    }
  }
}
