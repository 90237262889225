import { PersonalDataRequestStatus } from "../enums/personalDataRequetStatus";

export class CryptoUserModel {

  /** Идентификатор */
  id: number;

  /** Электронная почта */
  email: string;

  nickname: string;

  isNicknameOn: boolean;

  lastname: string;

  firstname: string;

  middlename: string;

  userName: string;

  nationality: number;

  country: number;

  city: string;

  twoFactorEnabled: boolean;

  ethAddresss: string;
  systemEthAddress: string;

  typeAuthorization: number;

  /** Электронная почта подтверждена */
  emailConfirmed: boolean;

  /** Номер телефона */
  phone: string;

  /** Номер телефона подтвержден */
  phoneConfirmed: boolean;

  avatarUrl: string;

  /** Файл новой фотографии */
  avatar: string;

  isConfirmed: boolean;

  hasParticipant: boolean;

  role: number;

  fioRefUser: string;

  myRef: string;

  myPremRef: string;

  userStatus: number;

  listRefInvater: string[];

  refUser: number;

  order: number;

  isPaid: boolean;

  timeDifference: number;

  lastIncomeDate: Date;

  isShowBtn: boolean;

  percent: number;

  statusName: string;

  checksumEthAddress: string;
  ethAddress: string;
  registerDate: string;

  ipAddress: string;

  firstEntryCountry: string;

  firstEntryCity: string;

  hasSentVerificationRequest: boolean;

  isVerificaionRequestPending: boolean;
  
  telegramId: string;

  telegramUsername: string;

  investedUsd: number;

  tokenUsdSum: number;

  profit: number;
  profitMinusSucFee: number;

  userDividends: any[];

  /** 
   * Был отправлен хотя бы один запрос на верификацию 
   * */
  hasVerificationRequest: boolean;
  /** 
   * Id юзера при верификации в Sumsub, на беке указан как ApplicantId
   */
  sumsubId: string;

  // verificationType: enums.VerificationType;
  verificationStatus: PersonalDataRequestStatus;
  /** 
   * Верификация была удалена после смены email
   */
  verificationIsDeleted: boolean;

  /**
   * заблокирован ли юзер
   */
  deleted: boolean;

  /**
   * верифицирован ли в Sumsub
   */
  isVerified: boolean;
  
  /**
   * нужно ли спрятать баннер на главной странице
   */
  isHideMainBanner: boolean;

  /**
   * был ли зареган юзер по реф ссылке
   */
  isHasInviter: boolean;

  /**
   * включен ли у юзера Auto Compound
   */
  isAutoCompoundEnabled: boolean;

  skipDepositTutorial: boolean;
}