import { Injectable } from "@angular/core";
import { IResponse } from "../interfaces";
import { BaseFilterModel, MediaPlatformFilter, Track } from "../model";
import { ApiService } from "./api.service";
import { TracksFilterModel } from "../model/trackFilter.model";


const apiUrl = 'api/track/';

@Injectable({
  providedIn: 'root'
})

export class TrackService{

    constructor(private api: ApiService) { }
 
    public getComposition(searchRequest: TracksFilterModel): Promise<IResponse<Track[]>> {
        return this.api.post<Track[]>(apiUrl + "/getcomposition", searchRequest).toPromise();
    }

    public getTrackById(trackId: number): Promise<IResponse<Track>> {
      return this.api.get<Track>(apiUrl + "getTrackById/" + trackId).toPromise();
    }

    public getPlatforms(mediaPlatformFilter: MediaPlatformFilter): Promise<IResponse<number[]>> {
      return this.api.post<number[]>(apiUrl + "/getPlatforms/", mediaPlatformFilter).toPromise()
    }
}