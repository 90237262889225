import { Injectable } from '@angular/core';
import { WithdrawBySectionsPostRequest } from '../model/withdrawBySectionsPostRequest.model';
import { IResponse } from '../interfaces';
import { ApiService } from './api.service';

const withdrawSectionUrl = 'api/withdrawsecrion/';

@Injectable({
  providedIn: 'root'
})
export class WithdrawSectionService {

  constructor(private api: ApiService) { }

  public createWithdrawCom(code: number): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(withdrawSectionUrl + 'withdrawsectioncom/' + code).toPromise();
  }
}
