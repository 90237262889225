import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/component/base/base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../shared/services';
import { authSubpages } from './auth.subpages';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { docType } from '../shared/enums';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent extends BaseComponent implements OnInit {

  subpagesMenu = authSubpages;
  currentActiveTab : any;
  currentYear: any;
  environment = environment;
  isHiddenSupport: boolean = false;
  public docType = docType;
  isMobile: boolean;

  constructor(
    public authService: AuthService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private translate: TranslateService,
  ) {
    super(translate, modalService);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.currentYear = new Date().getFullYear();
    this.isMobile = window.innerWidth < 570;
  }

  ngOnInit(): void {
    var t = this;
    var subpageRoute = t.activateRoute.snapshot.paramMap.get('subpageRoute');
    t.currentActiveTab = t.subpagesMenu.find(tab => tab.route == subpageRoute);
    t.activateTab(t.currentActiveTab ?? t.subpagesMenu[4]);

    this.initTidioChat();
  }

  ngOnDestroy(){
    this.currentActiveTab.isActive = false;
  }

  public navigateTab(route: string){
    var t = this;
    t.router.navigateByUrl('/auth/' + route);
  }

  public activateTab(item) {
    var t = this;
    var currentTab = t.subpagesMenu.find(val => val.title == item.title);
    currentTab.isActive = true;
    t.currentActiveTab = currentTab;
  }

  openChat(): void {
    if ((window as any).tidioChatApi) {
      this.isHiddenSupport = true;
      (window as any).tidioChatApi.show();
      (window as any).tidioChatApi.open();
    }
  }

  initTidioChat(): void {
    let t = this;
    const onTidioChatApiReady = () => {
      (window as any).tidioChatApi.hide();

      (window as any).tidioChatApi.on('close', () => {
        (window as any).tidioChatApi.hide();
        t.resetStyles();
        t.isHiddenSupport = false;
      });
    };

    if ((window as any).tidioChatApi) {
      (window as any).tidioChatApi.on('ready', onTidioChatApiReady);
    } else {
      document.addEventListener('tidioChat-ready', onTidioChatApiReady);
    }
  }

  // костыль, чтобы убрать стили, которые вешает сама чатра и не убирает
  resetStyles(): void {
    const resetStyleProperties = (element: HTMLElement, properties: string[]) => {
      properties.forEach(prop => element.style[prop as any] = '');
    };
  
    const body = document.body;
    const html = document.documentElement;
    const propertiesToReset = ['overflow', 'height', 'width', 'visibility', 'display', 'inset', 'margin', 'opacity'];
  
    resetStyleProperties(body, propertiesToReset);
    resetStyleProperties(html, ['overflow', 'margin']);
  }
}
