export var ListCountry =
  [
    // { value: 0, name: 'ABKHAZIA' },
    { value: 1, name: 'AFGHANISTAN' },
    // { value: 2, name: 'ALAND ISLANDS' },
    { value: 3, name: 'ALBANIA' },
    { value: 4, name: 'ALGERIA' },
    // { value: 5, name: 'AMERICAN SAMOA' },
    { value: 6, name: 'ANDORRA' },
    { value: 7, name: 'ANGOLA' },
    // { value: 8, name: 'ANGUILLA' },
    // { value: 9, name: 'ANTARCTICA' },
    { value: 10, name: 'ANTIGUA AND BARBUDA' },
    { value: 11, name: 'ARGENTINA' },
    { value: 12, name: 'ARMENIA' },
    // { value: 13, name: 'ARUBA' },
    // { value: 14, name: 'ASCENSION' },
    // { value: 15, name: 'ASHMORE AND CARTIER ISLANDS' },
    { value: 16, name: 'AUSTRALIA' },
    // { value: 17, name: 'AUSTRALIAN ANTARCTIC TERRITORY' },
    { value: 18, name: 'AUSTRIA' },
    { value: 19, name: 'AZERBAIJAN' },


    { value: 20, name: 'BAHAMAS' },
    { value: 21, name: 'BAHRAIN' },
    // { value: 22, name: 'BAKER ISLAND' },
    { value: 23, name: 'BANGLADESH' },
    { value: 24, name: 'BARBADOS' },    
    // { value: 25, name: 'BELARUS' },
    { value: 26, name: 'BELGIUM' },
    { value: 27, name: 'BELIZE' },
    { value: 28, name: 'BENIN' },
    // { value: 29, name: 'BERMUDA' },
    { value: 30, name: 'BHUTAN' },
    { value: 31, name: 'BOLIVIA' },
    { value: 32, name: 'BOSNIA AND HERZEGOVINA' },
    { value: 33, name: 'BOTSWANA' },
    // { value: 34, name: 'BOUVET ISLAND' },
    { value: 35, name: 'BRAZIL' },    
    // { value: 36, name: 'BRITISH ANTARCTIC TERRITORY' },
    // { value: 37, name: 'BRITISH INDIAN OCEAN TERRITORY' },
    // { value: 38, name: 'BRITISH SOVEREIGN BASE AREAS' },
    // { value: 39, name: 'BRITISH VIRGIN ISLANDS' },
    { value: 40, name: 'BRUNEI' },
    { value: 41, name: 'BULGARIA' },
    { value: 42, name: 'BURKINA FASO' },
    { value: 43, name: 'BURMA (REPUBLIC OF THE UNION OF MYANMAR)' },
    { value: 44, name: 'BURUNDI' },


    { value: 48, name: 'CABO VERDE' },
    { value: 45, name: 'CAMBODIA' },
    { value: 46, name: 'CAMEROON' },
    { value: 47, name: 'CANADA' },    
    // { value: 49, name: 'CARIBBEAN NETHERLANDS' },
    // { value: 50, name: 'CAYMAN ISLANDS' },
    { value: 51, name: 'CENTRAL AFRICAN REPUBLIC' },
    { value: 52, name: 'CHAD' },
    { value: 53, name: 'CHILE' },    
    // { value: 54, name: 'CHINA' },
    // { value: 55, name: 'CHRISTMAS ISLAND' },
    // { value: 56, name: 'CLIPPERTON ISLAND' },
    // { value: 57, name: 'COCOS (KEELING) ISLANDS' },
    { value: 58, name: 'COLOMBIA' },
    { value: 59, name: 'COMOROS' },
    { value: 60, name: 'CONGO (REPUBLIC OF)' },
    // { value: 61, name: 'COOK ISLANDS' },
    // { value: 62, name: 'CORAL SEA ISLANDS' },
    { value: 63, name: 'COSTA RICA' },
    { value: 64, name: "COTE D'IVOIRE (IVORY COAST)" },
    { value: 65, name: 'CROATIA' },
    { value: 66, name: 'CUBA' },
    // { value: 67, name: 'CURACAO' },
    { value: 68, name: 'CYPRUS' },
    { value: 69, name: 'CZECH REPUBLIC' },


    { value: 70, name: 'DEMOCRATIC REPUBLIC OF THE CONGO' },
    { value: 71, name: 'DENMARK' },
    { value: 72, name: 'DJIBOUTI' },
    { value: 73, name: 'DOMINICA' },
    { value: 74, name: 'DOMINICAN REPUBLIC' },


    { value: 75, name: 'ECUADOR' },
    { value: 76, name: 'EGYPT' },
    { value: 77, name: 'EL SALVADOR' },
    { value: 78, name: 'EQUATORIAL GUINEA' },
    { value: 79, name: 'ERITREA' },
    { value: 80, name: 'ESTONIA' },
    { value: 280, name: 'ESWATINI' },
    { value: 81, name: 'ETHIOPIA' },

    // { value: 82, name: 'FALKLAND ISLANDS' },
    // { value: 83, name: 'FAROE ISLANDS' },
    { value: 84, name: 'FIJI' },
    { value: 85, name: 'FINLAND' },
    { value: 86, name: 'FRANCE' },
    // { value: 87, name: 'FRENCH GUIANA' },
    // { value: 88, name: 'FRENCH POLYNESIA' },
    // { value: 89, name: 'FRENCH SOUTHERN AND ANTARCTIC LANDS' },
    // { value: 90, name: 'FRENCH SOUTHERN TERRITORIES' },


    { value: 91, name: 'GABON' },
    { value: 92, name: 'GAMBIA' },
    { value: 93, name: 'GEORGIA' },
    { value: 94, name: 'GERMANY' },
    { value: 95, name: 'GHANA' },
    // { value: 96, name: 'GIBRALTAR' },
    { value: 97, name: 'GREECE' },
    // { value: 98, name: 'GREENLAND' },
    { value: 99, name: 'GRENADA' },
    // { value: 100, name: 'GUADELOUPE' },
    // { value: 101, name: 'GUAM' },
    { value: 102, name: 'GUATEMALA' },
    // { value: 103, name: 'GUERNSEY' },
    { value: 104, name: 'GUINEA' },
    { value: 105, name: 'GUINEA-BISSAU' },
    { value: 106, name: 'GUYANA' },


    { value: 107, name: 'HAITI' },
    // { value: 108, name: 'HEARD AND MCDONALD ISLANDS' },
    { value: 109, name: 'HONDURAS' },
    // { value: 110, name: 'HONG KONG' },
    // { value: 111, name: 'HOWLAND ISLAND' },
    { value: 112, name: 'HUNGARY' },


    { value: 113, name: 'ICELAND' },
    { value: 114, name: 'INDIA' },
    { value: 115, name: 'INDONESIA' },    
    // { value: 116, name: 'IRAN' },
    { value: 117, name: 'IRAQ' },
    { value: 118, name: 'IRELAND' },
    // { value: 119, name: 'ISLE OF MAN' },
    { value: 120, name: 'ISRAEL' },
    { value: 121, name: 'ITALY' },


    { value: 122, name: 'JAMAICA' },
    { value: 123, name: 'JAPAN' },
    // { value: 124, name: 'JARVIS ISLAND' },
    // { value: 125, name: 'JERSEY' },
    // { value: 126, name: 'JOHNSTON ATOLL' },
    { value: 127, name: 'JORDAN' },


    { value: 128, name: 'KAZAKHSTAN' },
    { value: 129, name: 'KENYA' },
    // { value: 130, name: 'KINGMAN REEF' },
    { value: 131, name: 'KIRIBATI' },
    // { value: 132, name: 'KOSOVO' },
    { value: 133, name: 'KUWAIT' },
    { value: 134, name: 'KYRGYZSTAN' },


    { value: 135, name: 'LAOS (LAO PEOPLE’S DEMOCRATIC REPUBLIC)' },
    { value: 136, name: 'LATVIA' },
    { value: 137, name: 'LEBANON' },
    { value: 138, name: 'LESOTHO' },
    { value: 139, name: 'LIBERIA' },
    { value: 140, name: 'LIBYA' },
    { value: 141, name: 'LIECHTENSTEIN' },
    { value: 142, name: 'LITHUANIA' },
    { value: 143, name: 'LUXEMBOURG' },


    // { value: 144, name: 'MACAU' },
    { value: 145, name: 'MACEDONIA (REPUBLIC OF NORTH MACEDONIA)' },
    { value: 146, name: 'MADAGASCAR' },
    { value: 147, name: 'MALAWI' },
    { value: 148, name: 'MALAYSIA' },
    { value: 149, name: 'MALDIVES' },
    { value: 150, name: 'MALI' },
    { value: 151, name: 'MALTA' },
    { value: 152, name: 'MARSHALL ISLANDS' },
    // { value: 153, name: 'MARTINIQUE' },
    { value: 154, name: 'MAURITANIA' },
    { value: 155, name: 'MAURITIUS' },
    // { value: 156, name: 'MAYOTTE' },
    { value: 157, name: 'MEXICO' },
    { value: 158, name: 'MICRONESIA' },
    // { value: 159, name: 'MIDWAY ISLANDS' },
    { value: 160, name: 'MOLDOVA (REPUBLIC OF MOLDOVA)' },
    { value: 161, name: 'MONACO' },
    { value: 162, name: 'MONGOLIA' },
    { value: 163, name: 'MONTENEGRO' },
    // { value: 164, name: 'MONTSERRAT' },
    { value: 165, name: 'MOROCCO' },
    { value: 166, name: 'MOZAMBIQUE' },


    // { value: 167, name: 'NAGORNO-KARABAKH' },
    { value: 168, name: 'NAMIBIA' },
    { value: 169, name: 'NAURU' },
    // { value: 170, name: 'NAVASSA ISLAND' },
    { value: 171, name: 'NEPAL' },
    { value: 172, name: 'NETHERLANDS' },
    // { value: 173, name: 'NEW CALEDONIA' },
    { value: 174, name: 'NEW ZEALAND' },
    { value: 175, name: 'NICARAGUA' },
    { value: 176, name: 'NIGER' },
    { value: 177, name: 'NIGERIA' },
    // { value: 178, name: 'NIUE' },
    // { value: 179, name: 'NORFOLK ISLAND' },   
    // { value: 180, name: 'NORTH KOREA' }, 
    // { value: 181, name: 'NORTHERN CYPRUS' },
    // { value: 182, name: 'NORTHERN MARIANA ISLANDS' },
    { value: 183, name: 'NORWAY' },


    { value: 184, name: 'OMAN' },


    { value: 185, name: 'PAKISTAN' },
    { value: 186, name: 'PALAU' },
    // { value: 187, name: 'PALESTINE' },
    // { value: 188, name: 'PALMYRA ATOLL' },
    { value: 189, name: 'PANAMA' },
    { value: 190, name: 'PAPUA NEW GUINEA' },
    { value: 191, name: 'PARAGUAY' },
    { value: 192, name: 'PERU' },
    // { value: 193, name: 'PETER I ISLAND' },
    { value: 194, name: 'PHILIPPINES' },
    // { value: 195, name: 'PITCAIRN' },
    // { value: 196, name: 'PITCAIRN ISLANDS' },
    { value: 197, name: 'POLAND' },
    { value: 198, name: 'PORTUGAL' },
    // { value: 199, name: 'PRIDNESTROVIE (TRANSNISTRIA)' },
    // { value: 200, name: 'PUERTO RICO' },


    { value: 201, name: 'QATAR' },
    // { value: 202, name: 'QUEEN MAUD LAND' },


    // { value: 203, name: 'REUNION' },
    { value: 204, name: 'ROMANIA' },
    // { value: 205, name: 'ROSS DEPENDENCY' },    
    // { value: 206, name: 'RUSSIAN FEDERATION' },
    { value: 207, name: 'RWANDA' },


    // { value: 208, name: 'SAINT BARTHELEMY' },
    // { value: 209, name: 'SAINT HELENA' },
    { value: 210, name: 'SAINT KITTS AND NEVIS' },
    { value: 211, name: 'SAINT LUCIA' },
    // { value: 212, name: 'SAINT MARTIN (FRANCE)' },
    // { value: 213, name: 'SAINT MARTIN (NETHERLANDS)' },
    // { value: 214, name: 'SAINT PIERRE AND MIQUELON' },
    { value: 215, name: 'SAINT VINCENT AND GRENADINES' },
    { value: 216, name: 'SAMOA' },
    { value: 217, name: 'SAN MARINO' },
    { value: 218, name: 'SAO TOME AND PRINCIPE' },
    { value: 219, name: 'SAUDI ARABIA' },
    { value: 220, name: 'SENEGAL' },
    { value: 221, name: 'SERBIA' },
    { value: 222, name: 'SEYCHELLES' },
    { value: 223, name: 'SIERRA LEONE' },
    { value: 224, name: 'SINGAPORE' },
    { value: 225, name: 'SLOVAKIA' },
    { value: 226, name: 'SLOVENIA' },
    { value: 227, name: 'SOLOMON ISLANDS' },
    { value: 228, name: 'SOMALIA' },    
    // { value: 229, name: 'SOMALILAND' },
    { value: 230, name: 'SOUTH AFRICA' },
    // { value: 231, name: 'SOUTH GEORGIA & SOUTH SANDWICH ISLANDS' },
    // { value: 232, name: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS' },
    { value: 233, name: 'SOUTH KOREA (REPUBLIC OF KOREA)' },
    // { value: 234, name: 'SOUTH OSSETIA' },
    { value: 235, name: 'SOUTH SUDAN' },
    { value: 236, name: 'SPAIN' },
    { value: 237, name: 'SRI LANKA' },
    { value: 238, name: 'SUDAN' },
    { value: 239, name: 'SURINAME' },
    // { value: 240, name: 'SVALBARD AND JAN MAYEN ISLANDS' },
    // { value: 241, name: 'SWAZILAND' },
    { value: 242, name: 'SWEDEN' },
    { value: 243, name: 'SWITZERLAND' },    
    // { value: 244, name: 'SYRIA' },


    // { value: 245, name: 'TAIWAN' },
    { value: 246, name: 'TAJIKISTAN' },
    { value: 247, name: 'TANZANIA' },
    { value: 248, name: 'THAILAND' },
    { value: 249, name: 'TIMOR-LESTE' },
    { value: 250, name: 'TOGO' },
    // { value: 251, name: 'TOKELAU' },
    { value: 252, name: 'TONGA' },
    { value: 253, name: 'TRINIDAD AND TOBAGO' },
    // { value: 254, name: 'TRISTAN DA CUNHA' },
    { value: 255, name: 'TUNISIA' },
    { value: 256, name: 'TURKEY' },
    { value: 257, name: 'TURKMENISTAN' },
    // { value: 258, name: 'TURKS AND CAICOS ISLANDS' },
    { value: 259, name: 'TUVALU' },


    { value: 260, name: 'UGANDA' },
    { value: 261, name: 'UKRAINE' },
    { value: 262, name: 'UNITED ARAB EMIRATES' },
    { value: 263, name: 'UNITED KINGDOM' },
    // { value: 264, name: 'UNITED STATES MINOR OUTLYING ISLANDS' },    
    // { value: 265, name: 'UNITED STATES OF AMERICA' },
    // { value: 266, name: 'UNITED STATES VIRGIN ISLANDS' },
    { value: 267, name: 'URUGUAY' },
    { value: 268, name: 'UZBEKISTAN' },


    { value: 269, name: 'VANUATU' },
    // { value: 270, name: 'VATICAN' },    
    // { value: 271, name: 'VENEZUELA' },
    { value: 272, name: 'VIETNAM' },


    // { value: 273, name: 'WAKE ISLAND' },
    // { value: 274, name: 'WALLIS AND FUTUNA ISLANDS' },
    // { value: 275, name: 'WESTERN SAHARAN' },


    { value: 276, name: 'YEMEN' },


    { value: 277, name: 'ZAMBIA' },
    { value: 278, name: 'ZIMBABWE' },

    
    { value: 279, name: 'UNKNOWN' }
  ];