<!-- Survey modal -->
<div
  class="card mx-auto border-0"
  *ngIf="!showLoadingModal && !showResultModal"
  style="min-height: 365px; background-color: black;"
  [ngStyle]="{ 'width': isMobile ? '335px' : '596px', 'background': !!bgImage ?  'url(' + bgImage + ') center / cover' : '#121212'}"
>
  <div class="mb-3">
    <div class="f-14 l-h-140 f-w-400 c-green">
      {{ 'Question' | lang}} {{currentQuestionNum}}
    </div>
  </div>
  <div class="card-body d-flex flex-column justify-content-between">
    <div>
      <!-- question -->
      <div class="row">
        <div class="col-sm-9 col-12">
          <div class="f-20 l-h-150 f-w-400 mb-2">
            {{ questionModelDict[currentQuestionNum]?.questionText | lang}}
          </div>
        </div>
      </div>
      <!-- answers -->
      <div class="row theme-form">
        <div
          class="col-12 form-check d-flex mb-2"
          *ngFor="let answer of questionModelDict[currentQuestionNum]?.answerOptionsList"
        >
          <input
            type="radio"
            name="answer"
            id="answerRadio"
            class="form-check-input ms-0 my-auto me-2 cursor-pointer"
            [value]="answer.answerId"
            [(ngModel)]="selectedAnswerId"
          />
          <div
            class="f-20 l-h-150 f-w-400 cursor-pointer"
            (click)="selectedAnswerId = answer.answerId"
          >
            {{ answer.answerText | lang }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <hr/>
      <div> 
        <!-- buttons -->
        <div class="row align-items-center">
          <div class="col f-16 l-h-150 f-w-400 pe-0">
            {{currentQuestionNum}} / {{questionAmount}}
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto pe-0">
                <button
                  class="btn btn-secondary w-100"
                  type="button"
                  (click)="getPrevQuestion()"
                  *ngIf="currentQuestionNum !== 1"
                >
                  {{'buttons.previous'| lang}}
                </button>
              </div>
              <div class="col-auto">
                <button
                  class="btn btn-primary w-100 d-flex align-items-center"
                  [disabled]="selectedAnswerId < 1"
                  (click)="getNextQuestion()"
                  type="button"
                  style="gap: 8px"
                  ngbAutofocus
                >
                  <div>
                    {{ (currentQuestionNum === questionAmount ? 'buttons.getResult'
                    : isMobile ? 'buttons.next' : 'buttons.nextQuestion') | lang}}
                  </div>
                  <img src="assets/images/ripe/icons/arrow-right-black.svg" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Loading modal -->
<div
  class="card mx-auto loading-modal"
  *ngIf="showLoadingModal"
  [ngStyle]="{ 'width': isMobile ? '335px' : '596px'}"
>
  <div class="row my-4 align-items-center justify-content-around">
    <svg width="0" height="0">
      <filter id="gooey-black-hole">
        <feGaussianBlur in="SourceGraphic" stdDeviation="20" result="blur" />
        <feColorMatrix
          in="blur"
          mode="matrix"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 50 -16"
          result="goo"
        />
      </filter>
    </svg>
    <div class="black-hole">
      <div
        class="loader-text c-green f-24"
        [class.single-digit]="loaderPercent < 10"
        [class.two-digit]="loaderPercent >= 10"
      >
        {{loaderPercent}}%
      </div>
      <ul class="gooey-container"></ul>
    </div>
  </div>
  <div
    class="row my-2 f-lg-26 f-20 f-w-600 l-h-125 text-uppercase justify-content-center"
  >
    {{ 'We calculate the result' | lang}}
  </div>
  <div class="row mb-3 c-green f-14 f-w-400 l-h-140 justify-content-center">
    {{ 'Wait a little longer...' | lang}}
  </div>
  <!-- <span class="loader"></span> -->
</div>

<!-- Result modal -->
<div
  class="card mx-auto result-modal hidden-scrollbar"
  *ngIf="showResultModal"
  [hidden]="showLoadingModal"
  [ngStyle]="{ 'width': isTablet ? '335px' : '739px', 'max-height': isTablet ? '600px' :  '785px' }"
>
  <div class="row justify-content-between mb-3">
    <div class="col-auto f-14 l-h-140 f-w-400 c-gray-semi-light">
      {{ 'Dear' | lang}} {{getCryptoUser().firstname}}, {{'we chose the best index for you.' | lang}}
    </div>
    <div class="col-auto p-0 d-flex justify-content-end">
      <div
        class="position-absolute d-flex top-0"
        style="padding: 25px 20px"
        [ngClass]="{'end-0' : isMobile}"
      >
        <img
          class="cursor-pointer"
          (click)="this.activeModal.close(true)"
          src="assets/images/ripe/icons/close-white.svg"
        />
      </div>
    </div>
  </div>
  <div class="row align-items-center justify-content-between">
    <div class="col-auto f-lg-36 f-26 l-h-120 f-w-600 text-uppercase">
      {{ indexInfo.title | lang}}
    </div>
    <div class="col-sm col-auto f-12 f-w-600 c-green ps-1">
      <img src="assets/images/ripe/icons/check-green.svg" />
      {{ 'riskLevel.' + indexInfo.riskLevel | lang}}
    </div>
  </div>
  <div class="row mb-sm-4 mb-3">
    <div class="col d-flex mt-2">
      <button
        class="btn btn-primary d-flex align-items-center"
        (click)="showPurchaseModal()"
        style="gap: 8px"
      >
        <div>{{ 'buttons.buyIndex' | lang}}</div>
        <img src="assets/images/ripe/icons/arrow-right-black.svg" />
      </button>
    </div>
  </div>
  <div class="row mb-sm-4 mb-3">
    <div class="col f-14 l-h-140 f-w-400">
      {{ indexInfo.shortTextContent | lang}}
    </div>
  </div>

  <!-- Artists -->
  <div class="row mb-sm-4 mb-2" *ngIf="indexInfo.isAvailable">
    <div class="col pe-1">
      <div class="row w-100 flex-nowrap" *ngIf="indexInfo.artistList?.length > 0">
        <div class="col pe-0">
          <app-carousel [trackCount]="indexInfo.artistList.length" #carousel 
            [showSwitches]="!isTablet" [loop]="false" [breakpoints]="artistCarouselBreakpoints">            
            <div class="col-auto pe-3 pb-2 keen-slider__slide track-item"
              *ngFor="let artist of indexInfo.artistList">
              <div class="card h-100 p-0 artist-card d-flex cursor-pointer border-0" 
                style="background: url('{{apiUrl}}{{artist.imageUrl}}') center / cover" 
                (mouseenter)="!isMobile && prevVideoUrl !== videoUrl && showVideo(apiUrl + artist.videoUrl)"
                (mouseleave)="hideVideo(); prevVideoUrl = null;">
                <div class="video-wrap d-flex flex-wrap flex-column h-100">
                  <div class="col-8 f-20 f-w-600 l-h-125 text-start text-uppercase">
                    {{artist.name}}
                  </div>
                  <div class="col align-self-start align-content-end show-video-btn cursor-pointer" *ngIf="isTablet">
                    <img src="assets/images/ripe/icons/play.svg" style="align-self: end;"
                     (click)="showVideo(apiUrl + artist.videoUrl)" (touchstart)="showVideo(apiUrl + artist.videoUrl)">
                  </div>
                </div>
                <video id="videoElement" class="w-100 h-100 position-absolute video-mask video-cover opacity-0" *ngIf="apiUrl + artist.videoUrl === activeVideoUrl" 
                  [attr.src]="activeVideoUrl" autoplay playsinline muted (loadeddata)="playVideo()" (ended)="hideVideo()"
                  type='video/mp4'></video>
              </div>
            </div>
          </app-carousel>
        </div>
      </div>
    </div>
  </div>

  <!-- Desktop index info -->
  <div class="row align-items-center mb-3" *ngIf="!isTablet">
    <div class="col-auto">
      <div class="f-14 f-w-400 l-h-140">{{ 'All time return' | lang }}</div>
      <div class="f-16 f-w-400 l-h-150 c-green">
        {{ indexInfo.allTimeReturn }}%
      </div>
    </div>
    <div class="col-auto divider"></div>
    <div class="col-auto">
      <div class="f-14 f-w-400 l-h-140">{{ 'Risk level' | lang }}</div>
      <div class="f-16 f-w-400 l-h-150 c-green">
        {{ 'riskLevel.' + indexInfo.riskLevel | lang }}
      </div>
    </div>
    <div class="col-auto divider"></div>
    <div class="col-auto">
      <div class="f-14 f-w-400 l-h-140">{{ 'Distribution' | lang }}</div>
      <div class="f-16 f-w-400 l-h-150 c-green">
        {{ indexInfo.distributionStr | lang }}
      </div>
    </div>
    <div class="col-auto divider"></div>
    <div class="col-auto">
      <div class="f-14 f-w-400 l-h-140 text-uppercase">{{ 'Apy' | lang }}</div>
      <div class="f-16 f-w-400 l-h-150 c-green text-lowercase">
        {{ 'Up to' | lang }} {{indexInfo.apy}}%
      </div>
    </div>
    <div class="col-auto divider"></div>
    <div class="col-auto">
      <div class="f-14 f-w-400 l-h-140">{{ 'Min Investment' | lang }}</div>
      <div class="f-16 f-w-400 l-h-150 c-green">
        ${{indexInfo.minInvestment}}
      </div>
    </div>
  </div>

  <!-- Mobile index info -->
  <div class="row" *ngIf="isTablet">
    <div class="col-auto">
      <div class="f-14 f-w-400 l-h-140">{{ 'All time return' | lang }}</div>
      <div class="f-16 f-w-400 l-h-150 c-green pb-3">
        {{ indexInfo.allTimeReturn }}%
      </div>
      <div class="f-14 f-w-400 l-h-140">{{ 'Distribution' | lang }}</div>
      <div class="f-16 f-w-400 l-h-150 c-green">
        {{ indexInfo.distributionStr | lang }}
      </div>
    </div>

    <div
      class="col-auto d-flex flex-column justify-content-around align-items-center p-0 mx-auto"
    >
      <div class="divider m-0"></div>
      <div class="divider m-0"></div>
    </div>

    <div class="col-auto">
      <div class="f-14 f-w-400 l-h-140">{{ 'Risk level' | lang }}</div>
      <div class="f-16 f-w-400 l-h-150 c-green pb-3">
        {{ 'riskLevel.' + indexInfo.riskLevel | lang }}
      </div>
      <div class="f-14 f-w-400 l-h-140 text-uppercase">{{ 'Apy' | lang }}</div>
      <div class="f-16 f-w-400 l-h-150 c-green text-lowercase">
        {{ 'Up to' | lang }} {{indexInfo.apy}}%
      </div>
    </div>
  </div>
  <div class="row mb-2" *ngIf="isTablet">
    <div class="col-auto">
      <div class="f-14 f-w-400 l-h-140 pt-3">{{ 'Min Investment' | lang }}</div>
      <div class="f-16 f-w-400 l-h-150 c-green">
        ${{indexInfo.minInvestment}}
      </div>
    </div>
  </div>

  <div class="row align-items-center mb-3">
    <div class="col f-20 f-w-600 l-h-125 text-uppercase pe-lg-3 pe-1">
      {{ 'Index composition' | lang }}
    </div>
    <div class="col-auto ps-0">
      <button
        class="btn btn-secondary w-100 px-auto"
        (click)="toggleExpansion()"
        style="padding-top: 2px; padding-bottom: 2px"
      >
        <span class="f-12 l-h-150 f-w-600"
          >{{'Details' | lang}}
          <img
            class="ms-1"
            [src]="isExpanded ? 'assets/images/ripe/icons/arrow_up_white.svg' : 
          'assets/images/ripe/icons/arrow_down_white.svg'"
            style="margin-bottom: 2px"
        /></span>
      </button>
    </div>
  </div>

  <!-- Tracks table desktop -->
  <div class="adm-table" *ngIf="isExpanded && !isTablet">
    <table class="table default-table-view mb-3 tracks-table">
      <thead>
        <tr>
          <th style="width: 35%">{{'Artists'| lang}}</th>
          <th style="width: 30%">{{'Track'| lang}}</th>
          <th style="width: 25%">{{'Index share'| lang}}</th>
          <th class="col-auto t-right">{{'Actions'| lang}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let track of tracksInfo">
          <td>{{track.artists}}</td>
          <td>{{track.title}}</td>
          <td class="c-green">{{track.indexShare}}%</td>
          <td class="col-auto t-right">
            <img class="cursor-pointer"
              src="assets/images/ripe/icons/play.svg"
              (click)="play(track.spotifyUri)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Tracks table mobile -->
  <div class="row" *ngIf="isExpanded && isTablet">
    <div
      class="col-12 mb-3"
      *ngFor="let track of tracksInfo"
      style="border-bottom: 1px solid black"
    >
      <div class="row align-items-center">
        <div class="col-9">
          <div
            class="f-12 f-w-300 l-h-140 truncate-text"
            style="max-width: 100%"
          >
            {{track.artists}}
          </div>
          <div
            class="f-20 f-w-600 l-h-125 text-uppercase truncate-text"
            style="max-width: 100%"
          >
            {{track.title}}
          </div>
        </div>
        <div class="col">
          <img
            class="cursor-pointer"
            src="assets/images/ripe/icons/play.svg"
            (click)="play(track.spotifyUri)"
            style="float: right"
          />
        </div>
      </div>
      <div class="row">
        <div class="col f-14 f-w-400 l-h-140">{{'Index share' | lang}}</div>
        <div class="col-auto f-14 f-w-400 l-h-140 c-green" style="float: right">
          {{ track.indexShare }}%
        </div>
      </div>
    </div>
  </div>

  <div
    class="card mb-0"
    [ngStyle]="{ 'background': isTablet ? 'url(assets/images/ripe/expected_returns_mobile.png) center / cover' :
    'url(assets/images/ripe/expected_returns.png) center / cover', 
    'min-height': isTablet ? '295px' : 'auto'}"
  >
    <div class="f-16 l-h-150 f-w-400 mb-2">{{'Expected returns' | lang}}</div>
    <div class="f-26 l-h-120 f-w-600 c-green text-uppercase mb-2">
      +{{indexInfo.expectedReturns.percentPerYear}}% {{'per year' | lang}}
    </div>
    <div [ngClass]="[ !isTablet ? 'col-7' : 'col']">
      <div class="row align-items-center">
        <div class="col-auto pe-0 pe-lg-3">
          <div class="f-14 f-w-400 l-h-140">{{'If I invest' | lang}}</div>
          <div class="f-16 f-w-400 l-h-150 c-green">
            ${{indexInfo.expectedReturns.investAmount}}
          </div>
        </div>
        <div class="col divider-line"></div>
        <div class="col-auto f-12 l-h-150 f-w-300 c-gray-semi-light p-0 p-lg-3">
          <div>
            {{'After' | lang}} {{indexInfo.expectedReturns.investmentHorizon}}
          </div>
        </div>
        <div class="col divider-line"></div>
        <div class="col-auto ps-0 pe-lg-3">
          <div class="f-14 f-w-400 l-h-140">{{'I will get' | lang}}</div>
          <div class="f-16 f-w-400 l-h-150 c-green">
            ${{indexInfo.expectedReturns.getAmount}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
