import { Injectable } from "@angular/core";
import { FrontChartInterval, IndexType } from "../enums";
import { IResponse } from "../interfaces";
import { IndexModel, IndexDividendsWithdrawModel, IndexFilterModel, IndexDividendsInfoModel, IndexDividendsWithdrawFilterModel } from "../model";
import { ApiService } from "./api.service";
import { ComboChartDataModel } from "../model/comboChartData.model";
import { BehaviorSubject } from "rxjs";
import { IndexInfo, IndexInfoByTypeDict } from "../constants";
import { DividendInfoModel } from "../model/dividendInfo.model";


const apiUrl = 'api/index/';

@Injectable({
  providedIn: 'root'
})

export class IndexService{
    private indexTypeSource = new BehaviorSubject<IndexType>(IndexType.Any);
    currentType = this.indexTypeSource.asObservable();

    constructor(private api: ApiService) { }
  
    changeIndexType(indexType: IndexType) {
      this.indexTypeSource.next(indexType);
    }

    // сброс значения после его использования
    getIndexType(): IndexType {
      var currentIndexType = this.indexTypeSource.getValue();
      this.indexTypeSource.next(IndexType.Any);
      return currentIndexType;
    }
 
    public getIndexInfo(type: IndexType): Promise<IResponse<IndexModel>> {
        return this.api.get<IndexModel>(apiUrl + "getIndexInfo/" + type).toPromise();
    }   

    public getUserAvailableDividends(type: IndexType): Promise<IResponse<number>> {
      return this.api.get<number>(apiUrl + "getUserAvailableDividends/" + type).toPromise();
    }

    public getUserDividendsTotalSumAsync(indexFilterModel: IndexFilterModel): Promise<IResponse<number>> {
      return this.api.post<number>(apiUrl + "getUserDividendsTotalSumAsync", indexFilterModel).toPromise();
    }
    
    public getUserDividendsHistoryForChart(type: IndexType): Promise<IResponse<ComboChartDataModel>> {
      return this.api.get<ComboChartDataModel>(apiUrl + "getUserDividendsHistoryForChart/" + type).toPromise();
    }

    public getUsersDivsIntervalled(chartInterval: FrontChartInterval, type: IndexType): Promise<IResponse<ComboChartDataModel>> {
      return this.api.get<ComboChartDataModel>(apiUrl + `getUserDivsIntervalled/${type}/${chartInterval}`).toPromise();
    }

    public distributeIndexDividends(trHash: string): Promise<IResponse<boolean>> {
      return this.api.get<boolean>(apiUrl + "distributeIndexDividends/" + trHash).toPromise();
    }

    public getIndexDividendsInfo(): Promise<IResponse<DividendInfoModel>> {
      return this.api.get<DividendInfoModel>(apiUrl + "getIndexDividendsInfo/").toPromise();
    }

    public getNextDistributionDate(type: IndexType): Promise<IResponse<string>>{
      return this.api.get<string>(apiUrl + "getNextDistributionDate/" + type).toPromise();
    }

    public getIndexesInfoByType(type: IndexType, uuid: string = ""): Promise<IResponse<IndexInfoByTypeDict>> {
      return this.api.get<IndexInfoByTypeDict>(apiUrl + `getIndexesInfoByType/${type}/${uuid}` ).toPromise();
    }

    public getIndexDividendsWithdraws(filter: IndexDividendsWithdrawFilterModel): Promise<IResponse<IndexDividendsWithdrawModel[]>> {
      return this.api.post<IndexDividendsWithdrawModel[]>(apiUrl + "getIndexDividendsWithdraws", filter).toPromise();
    }

    public getIndexDividendsInfos(): Promise<IResponse<IndexDividendsInfoModel[]>> {
      return this.api.get<IndexDividendsInfoModel[]>(apiUrl + "getIndexDividendsInfos").toPromise();
    }
}