import { Component, NgZone, OnInit, ViewChildren } from '@angular/core';
import { BaseComponent } from 'src/app/shared/component/base/base.component';
import { Router} from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService, CryptoUserService, PublicService} from '../../shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModal } from 'src/app/shared/component/modals/confirm';
import { ValidationUtils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ClientErrorCodes, docType } from 'src/app/shared/enums';
import { reCAPTCHA } from 'src/app/shared/constants';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { FacebookAnalyticsService } from 'src/app/shared/services/facebook-analytics.service';

const reCAPTCHAKey = reCAPTCHA.siteKey;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends BaseComponent implements OnInit {
  @ViewChildren('telegramAuthWidget') telegramAuthWidget: any;

  public registerForm: FormGroup;
  public refUser: string;
  public inviterLoaded: boolean = false;
  public inviter: string;
  public botName: string = environment.botName;
  public showPass: boolean = false;
  public errorText: string = '';
  public showReCAPTCHA = true;
  public reCaptchaInterval: any = {};
  public isSubscribed: boolean = false;
  public docType = docType;

  constructor(
    public authService: AuthService,
    private translate: TranslateService,
    private cryptoUserService: CryptoUserService,
    private publicService: PublicService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private ngZone: NgZone,
    private googleAnalyticsService: GoogleAnalyticsService,
    private facebookAnalyticsService: FacebookAnalyticsService
  ) {
    super(translate, modalService)
    var t = this;
    t.registerForm = fb.group({
      firstName: ['', [Validators.required, Validators.pattern(t.namePattern), Validators.maxLength(50), t.nameValidator.bind(this)]],
      lastName: ['', [Validators.required, Validators.pattern(t.namePattern), Validators.maxLength(50), t.nameValidator.bind(this)]],
      email: ['', [Validators.required, Validators.pattern(t.emailPattern)]],
      password: ['', [Validators.required]],
      passwordConfirm: ['', [Validators.required]]
    },
    {
      validator: t.passwordMatchValidator
    });

   }

  get firstName() { return this.registerForm.get('firstName'); }
  get lastName() { return this.registerForm.get('lastName'); }
  get email() { return this.registerForm.get('email'); }
  get password() { return this.registerForm.get('password'); }
  get passwordConfirm() { return this.registerForm.get('passwordConfirm'); }

  passwordMatchValidator(form: FormGroup) {
    var password = form.get('password');
    if(password.dirty && !password.untouched)
    {
      var isValidPassword = ValidationUtils.validatePassword(password.value);
      if(!isValidPassword.isValid)
      {
        let obj = {};
        obj[isValidPassword.errorText] = true;
        password.setErrors(obj);
      }
    }

    var passwordConfirm = form.get('passwordConfirm');
    if(passwordConfirm.dirty && !passwordConfirm.untouched)
    {
      if (!passwordConfirm.value) {
        return;
      }

      var isValidPasswordConfirm = ValidationUtils.validatePasswordConfirmation(password.value, passwordConfirm.value);
      if(!isValidPasswordConfirm.isValid)
      {
        let obj = {};
        obj[isValidPasswordConfirm.errorText] = true;
        passwordConfirm.setErrors(obj);
      }
      else passwordConfirm.updateValueAndValidity({ onlySelf: true });
    }

  }

  nameValidator(name: FormGroup) {
    var t = this;
    let minLength = 2;
    let error = {};

    if (name.value?.trim().length < minLength) {
      let errorText = t.translate.instant('errors.minLengthTemplated');
      error[errorText.replace("#length#", minLength.toString())] = true;
      return error;
    }
  }

  ngOnInit(): void {
    (<any>window).onTelegramAuth = this.onTelegramAuth.bind(this);
    var t = this;
    //t.loadScript('recaptcha');
    t.reCaptchaInterval = setInterval(function() {
      if (!!(<any>window).grecaptcha && !!(<any>window).grecaptcha.render) {
        (<any>window).grecaptcha.render(
          'captchaField',
          { 'sitekey': reCAPTCHAKey,
            'callback': 'recaptcha_callback',
            hl: 'en',
            'theme' : 'dark'
          });
          clearInterval(t.reCaptchaInterval);
      }
    }, 500);
    this.loadInviter();
  }

  ngAfterViewInit() {
    //this.createTelegramRegScript();
  }

  //На данный момент не используется
  // public createTelegramRegScript() {
  //   if (document.getElementById("telegram-reg-script") != null) {
  //     return;
  //   }

  //   var size = "large";
  //   var dataRadius = "20";

  //   const tgScript = document.createElement('script');
  //   tgScript.src = "https://oauth.tg.dev/js/telegram-widget.js?21";
  //   tgScript.setAttribute('id', 'telegram-reg-script');
  //   tgScript.setAttribute('data-telegram-login', this.botName || 'samplebot');
  //   tgScript.setAttribute('data-size', size);
  //   tgScript.setAttribute('data-request-access', 'write');
  //   tgScript.setAttribute('data-radius', dataRadius);
  //   tgScript.setAttribute('data-onauth', 'onTelegramAuth(user)');
  //   tgScript.async = true;

  //   this.telegramAuthWidget.first.nativeElement.appendChild(tgScript);
  // }


  onTelegramAuth(authData: Object): void {
    var t = this;

    authData["first_name"] = authData["first_name"] != null ?
      encodeURIComponent(authData["first_name"]) : authData["first_name"];

    authData["last_name"] = authData["last_name"] != null ?
      encodeURIComponent(authData["last_name"]) : authData["last_name"];

    var authDataStr = JSON.stringify(authData);
    localStorage.setItem("telegramAuthData", authDataStr);

    var emptyCred = {
      email: "",
      password: "",
      confirmCode: ""
    };

    t.setLoading(true);
    t.authService.SignIn(emptyCred)
      .then((resp) => {
        t.setLoading(false);
        t.ngZone.run(() => t.router.navigate(['/overview']));
      })
      .catch((e => {
        if (e != null && e.error != null && e.error.error == "invalid_grant") {
          t.showError(t.translate.instant(e.error.error_description));
        }
        else t.showResponseError(e);

        t.setLoading(false);
      }));
  };

  // проверка на сущестование реф юзера
  public loadInviter() {

    // пока закомментил, сделал другой метод
    // потому что в куки не сохраняется ссылка
    // ссылка напрямую ищется с урла, по которому открылось окно регистрации

    /* var c_name = "ref";
    var result;
    if (document.cookie.length > 0) {
      var c_start = document.cookie.indexOf(c_name + "=");

      if (c_start != -1) {
        c_start = c_start + c_name.length + 1;
        var c_end = document.cookie.indexOf(";", c_start);

        if (c_end == -1) {
          c_end = document.cookie.length;
        }
        result = document.cookie.substring(c_start, c_end);
      }
    }

    if (result != null && result.length >= 0) {
      t.refUser = result;
    }

    if (t.refUser) {
      t.cryptoUserService.getRefName(t.refUser)
        .then((result) => {
          t.inviter = result.data;
          t.inviterLoaded = true;
        });
    } else t.inviterLoaded = true; */

    let t = this;
    var refValue = t.getPathValueByKey("ref=");
    // если реф значение есть, то юзер перешел по реф ссылке
    if (!!refValue) {
      t.refUser = refValue;
      // получение имени инвайтера (пока убрал)
      /* t.cryptoUserService.getRefName(t.refUser)
        .then((result) => {
          t.inviter = result.data;
        }); */
    }
    t.inviterLoaded = true;
  }

  public registration() {
    var t = this;

    if (t.registerForm.invalid) {
      t.markFormGroupTouchedAndDirty(t.registerForm)
      return;
    }

    var uuid = t.getCookieValue('mindboxDeviceUUID');

    t.setLoading(true);
    localStorage.removeItem("telegramAuthData");
    var firstName = t.firstName.value?.trim().replace(/\s+/g, ' ');
    var lastName = t.lastName.value?.trim().replace(/\s+/g, ' ');

    t.publicService.register({
      contact: t.email.value,
      password: t.password.value,
      confirmPassword: t.passwordConfirm.value,
      captcha: (<any>window).recaptcha_code,
      refUser: t.refUser,
      localization: t.getCurrentLocalization(),
      firstName: firstName,
      lastName: lastName,
      isSubscribed: t.isSubscribed,
      uuid: uuid,
    }).then((resp: any) => {
      t.showSuccess(t.translate.instant("Registration completed successfully") + ". " + t.translate.instant("Activation link has been sent to your e-mail") + ": " + t.email.value, "SYSTEM");
      t.googleAnalyticsService.pushEvent('form_sign_up', 'form_action', 'sign_up');
      t.facebookAnalyticsService.trackEvent('CompleteRegistration');
      t.router.navigate(['/auth/login']);
      //пока так, потому что это усложняет вход с капчей. как разберёмся, надо вернуть
      //без этого метода скорее всего нельзя будет зайти через письмо
      // t.authService.SignIn({email: t.email.value,  password: t.password.value})
      //   .then(() => {
      //     t.showSuccess(t.translate.instant("Registration completed successfully") + ". " + t.translate.instant("Activation link has has been sent to your e-mail") + ": " + t.email.value, "SYSTEM");
      //     t.router.navigate(['/auth/login']);
      //   });
    }).catch((e) => {
      if(!!e.error && !!e.error.errors[0] && e.error.errors[0].code === ClientErrorCodes.InvalidCaptcha){
        t.showReCAPTCHA = true;
        t.errorText = 'Please confirm that you are not a robot.';
        (<any>window).grecaptcha?.reset();
      }
      else {
        t.showResponseError(e);
        (<any>window).grecaptcha?.reset();
      }
    }).finally(() => {
      t.setLoading(false);
    });
  }

  public navigateTab(route: string){
    var t = this;
    t.router.navigateByUrl('/auth/' + route);
  }
}
