import { Injectable } from "@angular/core";
import { IResponse } from "../interfaces";
import { ApiService } from "./api.service";

const apiUrl = "api/sumsub-verification/";

@Injectable({
  providedIn: 'root'
})

export class SumSubVerificationService{

    constructor(private api: ApiService) { }
 
    public getSDKtoken(): Promise<IResponse<string>> {
      return this.api.get<string>(apiUrl + "getSDKtoken").toPromise()
    }
}