export class QuestionModel {
    questionText: string = "";
    answerOptionsList: AnswerModel[] = [];
}

export class AnswerModel {
    answerId: number = 0;              // id варианта ответа
    answerText: string = "";
    score: number = 0;
}
  
/// key - question number
export class QuestionModelDict {
    [key: number]: QuestionModel
}

/// key - question number
export class SurveyProgressModel {
    answerOptionsDict: SurveyProgressDict;
    result?: number = -1;
}

export class SurveyProgressDict {
    [key: number]: AnswerModel;
}