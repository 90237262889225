import { InfoAnalyticTypeEnum } from "../enums";

export class WithdrawBySectionsPostRequest {
  sectionAddress: string;
  sectionName: string;
  withdawAmount: number;
  code2fa: string;
  infoAnaliticType: InfoAnalyticTypeEnum;
  gasPrice?: number;
  gasLimit?: number;
}