<div class="auth-block mt-5">
  <p class="auth-bg-title f-26 align-center-900 text-center text-sm-start">{{"Build wealth with music royalties" | lang}}</p>
  <div class="card-body"> 
    <form class="theme-form" [formGroup]="registerForm">
      <div class="form-group">
        <input class="form-control" formControlName="firstName" placeholder="{{'First name' | lang}}">
        <div *ngIf="elemIsInvalid(firstName)" class="text text-danger mt-1">
          {{textErrorStr(firstName, namePattern)| lang}}
        </div>
      </div>
      <div class="form-group">
        <input class="form-control" formControlName="lastName" placeholder="{{'Last name' | lang}}">
        <div *ngIf="elemIsInvalid(lastName)" class="text text-danger mt-1">
          {{textErrorStr(lastName, namePattern)| lang}}
        </div>
      </div>        
      <div class="form-group">
        <input type="email" placeholder="{{'Email' | lang}}" class="form-control" formControlName="email">
        <div *ngIf="elemIsInvalid(registerForm.controls.email)" class="text text-danger mt-1">
          {{textErrorStr(registerForm.controls.email, emailPattern)| lang}}
        </div>
      </div>        

      <div class="form-group">    
        <input formControlName="password" class="form-control"  [type]="!showPass? 'password' : 'text'" placeholder="{{'Password' | lang}}"
        (input)="registerForm.get('password').markAsDirty()" (focus)="registerForm.get('password').markAsTouched()">      
        <div *ngIf="elemIsInvalid(registerForm.controls.password)" class="text text-danger mt-1">
          {{textErrorStr(registerForm.controls.password)| lang}}
        </div>
      </div> 

      <div class="form-group">    
        <input formControlName="passwordConfirm" class="form-control" [type]="!showPass? 'password' : 'text'" placeholder="{{'Confirm password' | lang}}"
        (input)="registerForm.get('passwordConfirm').markAsDirty()" (focus)="registerForm.get('passwordConfirm').markAsTouched()">      
        <div *ngIf="elemIsInvalid(registerForm.controls.passwordConfirm)" class="text text-danger mt-1">
          {{textErrorStr(registerForm.controls.passwordConfirm)| lang}}
        </div>
      </div> 

      <div class="form-check">
        <input id="checkbox" type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="isSubscribed" class="form-check-input" />
        <label
          for="checkbox"
          class="ps-2 f-14 align-top pt-1"
        >{{ 'I want to receive the newsletter from Ripe Capital' | lang }}</label>
      </div>

      <div *ngIf="!!errorText" class="text text-danger text-center">
        <img src="assets/images/danger.svg"/>&nbsp;{{errorText | lang}}
      </div>
      <div *ngIf="showReCAPTCHA" class="mt-3" id="captchaField" style="text-align: -webkit-center;"></div>

      <div class="form-group mt-3">
        <button class="btn btn-primary btn-block" (click)="registration()">
          {{"Sign Up" | lang}}
        </button>
      </div>

      <!-- <div class="row px-3">
        <div class="col login-divider"></div>
        <div class="col-auto login-divider-text text-uppercase">{{'or'| lang}}</div>
        <div class="col login-divider"></div>
      </div>

      <div class="form-group">
        <div class="telegram-reg-widget" id="telegramAuthWidget" #telegramAuthWidget></div>
      </div> -->

      <div class="desktop-show auth-card-footer">
        {{"Already have an account" | lang}}?
        <a class="c-green" href="javascript:void(0)"
          (click)="navigateTab('login')">{{"Sign In" | lang}}</a>
      </div>
    </form>
  </div>
</div> 