import { Injectable } from "@angular/core";
import { IResponse } from "../interfaces";
import { ApiService } from "./api.service";
import { QuestionModelDict, SurveyPostRequest, SurveyProgressDict, SurveyProgressModel } from "../model";
import { SurveyType } from "../enums";


const apiUrl = 'api/survey/';

@Injectable({
  providedIn: 'root'
})

export class SurveyService{

    constructor(private api: ApiService) { }

    public getSurveyQuestionsByType(type: SurveyType): Promise<IResponse<QuestionModelDict>> {
      return this.api.get<QuestionModelDict>(apiUrl + "getSurveyQuestionsByType/" + type).toPromise();
    }

    public getSurveyProgressByType(type: SurveyType): Promise<IResponse<SurveyProgressModel>> {
        return this.api.get<SurveyProgressModel>(apiUrl + "getSurveyProgressByType/" + type).toPromise();
    }

    public setSurveyProgress(request: SurveyPostRequest): Promise<IResponse<SurveyProgressModel>> {
        return this.api.post<SurveyProgressModel>(apiUrl + "setSurveyProgress/", request).toPromise();
    }
}