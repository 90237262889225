import { BonusType, CurrencyType } from "../../enums";

export class BaseBonusModel {
    /** Айдишник юзера, который учавствует в событии */
    UserId?: number;
    /** Тип бонуса */    
    BonusType: BonusType;
    /** Подтип бонуса */
    SubType: number;
    /** Валюта, в которой выплачивается бонус */
    CurrencyType: CurrencyType
}