import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services';

@Injectable({
    providedIn: 'root'
})

export class SecureInnerPagesGuard implements CanActivate {
  
    defaultRedirectPath: string = 'overview';

    constructor(private authService: AuthService, private router: Router,
        private userService: UserService) { }
        
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        var t = this;
        // если не залогинен
        if (!t.authService.isLoggedIn) {
          t.goAway();
        }
        // если залогинен, то проверяем роли доступа к компоненту
        else {
          var accessRoles = next.data['roles'];

          if(!accessRoles) return true;

          if (t.userService.getRole() == null) {
            const promise = new Promise<boolean>((resolve, reject) => {
              t.userService.refresh()
                .then((resp) => {
                  resolve(t.verifyRole(accessRoles));
                })
                .catch((error) => {
                  reject(t.goAway());
                })
            });
            return promise;
          }
          else return t.verifyRole(accessRoles);
        }
      }
    
      public verifyRole(accessRoles: string[]): boolean {
        var t = this;
        var resp = false;
        var userRoles = t.userService.getRole();
        accessRoles.forEach(role => {
          if (userRoles.indexOf(role) != -1) {
            resp = true;
          }
        });
    
        return resp ? resp : t.goAway(t.defaultRedirectPath);
    }

    public goAway(redirectPath: string = null): boolean {
      if (!!redirectPath)
        this.router.navigateByUrl(redirectPath);
      else 
        this.authService.SignOut();
      return false;
    }
}
