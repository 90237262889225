<div class="row banner">
  
  <div class="col-12 col-lg-7 info-img" [style.backgroundImage]="'url('+imgUrl+')'">
    
  </div>

  <div class="col card col-lg-5 card-modal-bg-color m-0 info-text noselect">
    <h2 class="text-uppercase">{{'banner.title' | lang}}</h2>
    <div class="card-body d-flex flex-column justify-content-between">
      <div class="f-14">
        <ul style="list-style-type:disc" class="ps-3">
          <li>{{"banner.textLine1" | lang}}</li>
          <li>{{"banner.textLine2" | lang}}</li>
          <li>{{"banner.textLine3" | lang}}</li>
        </ul>
      </div>

      <div class="mt-4">
        <button class="btn btn-primary text-nowrap w-100" type="button"
        (click)="close()">{{'buttons.getStarted' | lang}}
        <img class="ps-1" src="assets/images/ripe/right-icon-black.svg">
        </button>
      </div>
    </div>
  </div>  
  <div class="cross">
    <button type="button" class="btn-close btn-close-white" aria-label="Close" type="button"
    (click)="close()">
    </button>
    </div>
</div>